import { _DEFAULT_HOST } from '../app/app.const';

interface PartnerRedirect {
  weight: number;
  deliveryAddress: string;
  deliveryCountryCode: string;
  pickUpAddress: string;
  pickUpCountryCode: string;
  shipmentType: string;
}

export class Response {
  code?: string;
  codeHuman?: string;
  name: string;
  email: string;
  phone?: string;
  companyName?: string;
  position?: string;
  countryCode?: string;
  consignmentCode?: string;
  industry?: string;
  ipAddress: string;
  ipCountryCode?: string;
  languageCode: string;
  message: string;
  hasCod: boolean = false;
  hasDelivery: boolean = false;
  hasFulfillment: boolean = false;
  website: string;
  isVerified: boolean = false;
  hasParcel: boolean = false;
  partnerRedirect: PartnerRedirect;
  weight: number;
  deliveryAddress: string;
  deliveryCountryCode?: string;
  pickUpAddress: string;
  pickUpCountryCode?: string;
  hasReturns: boolean = false;
  host?: string;
  type: string;
  deliveryCountryCodes: string[] = [];
  parcelSize?: string;
  technologies: string[] = [];
  shipmentType: string;
  volumeEstimate?: string;
  warehouseLocation?: string;
  timestamp?: Date;
  brandName: string;

  constructor({
    name,
    email,
    phone,
    companyName,
    position,
    countryCode,
    consignmentCode,
    industry,
    ipAddress,
    ipCountryCode,
    languageCode,
    message,
    hasCod,
    hasDelivery,
    hasFulfillment,
    website,
    isVerified,
    hasParcel,
    partnerRedirect,
    weight,
    deliveryAddress,
    deliveryCountryCode,
    pickUpAddress,
    pickUpCountryCode,
    hasReturns,
    deliveryCountryCodes,
    parcelSize,
    shipmentType,
    technologies,
    volumeEstimate,
    warehouseLocation,
    type,
    host,
    brandName,
  }: {
    name: string;
    email: string;
    phone: string;
    companyName: string;
    position: string;
    countryCode: string;
    consignmentCode: string;
    industry: string;
    ipAddress: string;
    ipCountryCode: string;
    languageCode: string;
    message: string;
    hasCod: boolean;
    hasDelivery: boolean;
    hasFulfillment: boolean;
    website: string;
    isVerified: boolean;
    hasParcel: boolean;
    partnerRedirect: PartnerRedirect;
    weight: number;
    deliveryAddress: string;
    deliveryCountryCode?: string;
    pickUpAddress: string;
    pickUpCountryCode?: string;
    hasReturns: boolean;
    deliveryCountryCodes: string[];
    parcelSize?: string;
    shipmentType: string;
    technologies: string[];
    volumeEstimate?: string;
    warehouseLocation?: string;
    type: string;
    host?: string;
    brandName: string;
  }) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.companyName = companyName;
    this.position = position;
    this.countryCode = countryCode;
    this.consignmentCode = consignmentCode;
    this.industry = industry;
    this.ipAddress = ipAddress;
    this.ipCountryCode = ipCountryCode;
    this.languageCode = languageCode;
    this.message = message;
    this.hasCod = hasCod;
    this.hasDelivery = hasDelivery;
    this.hasFulfillment = hasFulfillment;
    this.website = website;
    this.isVerified = isVerified;
    this.hasReturns = hasReturns;
    this.deliveryCountryCodes = deliveryCountryCodes;
    this.parcelSize = parcelSize;
    this.technologies = technologies;
    this.volumeEstimate = volumeEstimate;
    this.warehouseLocation = warehouseLocation;
    this.hasParcel = hasParcel;
    this.partnerRedirect = {
      weight,
      deliveryAddress,
      deliveryCountryCode,
      pickUpAddress,
      pickUpCountryCode,
      shipmentType,
    };

    this.type = type;
    this.host = host ?? _DEFAULT_HOST;
    this.brandName = brandName;
  }
}
