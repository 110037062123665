import languageMap
  from './form-contact-box-eu.language';

import {
  Component,
  HostBinding,
  Input
} from '@angular/core';

import {
  getLabelLanguage
} from '../../app/app.utils';

@Component({
  selector: 'biz-form-contact-box-eu',
  templateUrl: './form-contact-box-eu.component.html',
  styleUrls: [
    './form-contact-box-eu.component.scss'
  ]
})
export class FormContactBoxEuComponent {
  @HostBinding('class.new')
  @Input()
  public isNew: boolean = false;

  @HostBinding('class.white')
  @Input()
  public whiteTheme: boolean = false;


  @HostBinding('class.horizontal')
  @Input()
  public horizontalLayout: boolean = false;


  @Input()
  public addressKey: string = 'address';

  @Input()
  public cityKey: string = 'athens';

  @Input()
  public countryKey: string = 'greece';

  @Input()
  public companyName: string = 'BIZ Courier & <span class="highlight">Logistics S.A.</span>';

  @Input()
  public postcodeKey: string = 'postcode';

  @Input()
  public workingHoursKey: string = 'biz-working-hours';

  @Input()
  public weekendWorkingDaysKey: string = '';

  @Input()
  public weekendWorkingHoursKey: string = '';

  @Input()
  public languageCode: string;

  public getLabelLanguage(
    key: string
  ) {
    return (
      getLabelLanguage(
        key,
        this.languageCode,
        languageMap
      )
    );
  }
}