import {
  Injectable
} from '@angular/core';

import {
  HttpClient
} from '@angular/common/http';

import {
  map
} from 'rxjs/operators';

import {
  IpAddressRes,
  IpGeolocationRes,
  Response,
  ResponseUpsertRes
} from '../models';

import {
  _FORMS_SERVER_URL,
  API_URL_IP_ADDRESS,
  API_URL_IP_GEOLOCATION
} from '../app/app.const';

@Injectable()
export class ResponseService {
  constructor(
    private http: HttpClient
  ) {}

  public getIpAddress(): (
    Promise<string>
  ) {
    return (
      this.http
          .get(
            API_URL_IP_ADDRESS
          )
          .pipe(
            map(
              (
                data: IpAddressRes
              ) => (
                data.ip
              )
            )
          )
          .toPromise()
    );
  }

  public getIpCountryCode(
    ipAddress: string
  ): (
    Promise<string>
  ) {
    return (
      this.http
          .get(
            API_URL_IP_GEOLOCATION
              .replace(
                '{ipAddress}',
                ipAddress
              )
          )
          .pipe(
            map(
              (
                data: IpGeolocationRes
              ) => (
                (
                  (
                    data
                      .status
                  ) ===
                  'success'
                ) ?
                  (
                    data
                      .countryCode
                  ) :
                  undefined
              )
            )
          )
          .toPromise()
    );
  }

  public upsertResponse(
    response: Response
  ): Promise<ResponseUpsertRes> {
    return (
      this.http
          .post(
            `${_FORMS_SERVER_URL}api/responses`,
            [
              response
            ]
          )
          .pipe(
            map(
              (
                res: ResponseUpsertRes
              ) => res
            )
          )
          .toPromise()
    );
  }
}