import languageMap
  from './form-contact-box.language';

import {
  Component,
  Input
} from '@angular/core';

import {
  getLabelLanguage
} from '../../app/app.utils';

@Component({
  selector: 'biz-form-contact-box',
  templateUrl: './form-contact-box.component.html',
  styleUrls: [
    './form-contact-box.component.scss'
  ]
})
export class FormContactBoxComponent {
  @Input()
  public languageCode: string;

  public getLabelLanguage(
    key: string
  ) {
    return (
      getLabelLanguage(
        key,
        this.languageCode,
        languageMap
      )
    );
  }
}