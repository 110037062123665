export const production = true;

export const defaultHost = 'https://bizcourier.eu';
export const defaultHostEu = 'https://fulfillmentbybiz.com';

export const bizfulfillmentDE = 'https://bizfulfillment.de';
export const bizfulfillmentHU = 'https://bizfulfillment.hu';

export const formsServerUrl = 'https://forms.bizcourier.eu/';

export const abcplusHost = 'https://abc-plus.eu';