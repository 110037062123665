import {
  MESSAGE_MIN_LENGTH,
  PREFERED_BRAND_NAMES
} from '../../app/app.const';

function getQueryParam(name) {
  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get(name);
  if (paramValue && PREFERED_BRAND_NAMES.includes(paramValue)) {
    return paramValue;
  }
}

const brandName = getQueryParam('brandName') || 'biz';

export default {
  'required-fields': {
    'en': 'Please fill all required fields',
    'el': 'Παρακαλούμε συμπληρώστε όλα τα υποχρεωτικά πεδία',
    'bg': 'Моля, попълнете всички задължителни полета',
    'fr': 'Veuillez compléter tous les champs obligatoires',
    'de': 'Bitte alle Pflichtfelder ausfüllen',
    'de-at': 'Bitte alle Pflichtfelder ausfüllen',
    'es': 'Cumplimente todos los campos necesarios',
    'it': 'Compilare tutti i campi obbligatori',
    'hr': 'Ispunite sva obavezna polja',
    'hu': 'Kérjük, az összes kötelező mezőt töltse ki',
    'pl': 'Wypełnij wszystkie wymagane pola',
    'pt': 'Preencha todos os campos obrigatórios',
    'ro': 'Vă rugăm să completați toate câmpurile obligatorii',
    'ru': 'Заполните все необходимые поля',
    'sk': 'Vyplňte všetky povinné polia',
    'sl': 'Prosimo, izpolnite vsa obvezna polja',
    'cs': 'Vyplňte všechna povinná pole'
  },
  'personal-contact-info': {
    'en': 'Personal Contact Info',
    'el': 'Στοιχεία Αποστολέα',
    'bg': 'Лична информация за връзка',
    'fr': 'Infos contact personnel',
    'de': 'Persönliche Kontaktdaten',
    'de-at': 'Persönliche Kontaktdaten',
    'es': 'Información de contacto personal',
    'it': 'Dati personali ',
    'hr': 'Osobni podaci za kontakt',
    'hu': 'Személyes kapcsolattartási adatok',
    'pl': 'Osobiste dane kontaktowe',
    'pt': 'Informações de contacto pessoal',
    'ro': 'Informații personale de contact',
    'ru': 'Контактная информация',
    'sk': 'Osobné kontaktné údaje',
    'sl': 'Osebni kontaktni podatki',
    'cs': 'Osobní kontaktní údaje'
  },
  'name': {
    'en': 'Name',
    'el': 'Όνομα',
    'bg': 'Име',
    'fr': 'Nom',
    'de': 'Name',
    'de-at': 'Name',
    'es': 'Nombre',
    'it': 'Nome',
    'hr': 'Ime',
    'hu': 'Név',
    'pl': 'Imię i nazwisko',
    'pt': 'Nome',
    'ro': 'Nume',
    'ru': 'Имя',
    'sk': 'Meno',
    'sl': 'Ime',
    'cs': 'Jméno'
  },
  'name-placeholder': {
    'en': 'Type your full name here',
    'el': 'Εισάγετε το ονοματεπώνυμό σας',
    'bg': 'Въведете пълното си име тук',
    'fr': 'Saisissez votre nom et prénom ici',
    'de': 'Bitte Ihren vollständigen Namen eingeben',
    'de-at': 'Bitte Ihren vollständigen Namen eingeben',
    'es': 'Escriba su nombre completo',
    'it': 'Inserire qui nome e cognome',
    'hr': 'Ovdje upišite svoje ime i prezime',
    'hu': 'Ide írja be a teljes nevét',
    'pl': 'Wpisz tutaj swoje imię i nazwisko',
    'pt': 'Introduza aqui o nome completo',
    'ro': 'Tastați numele dvs. complet aici',
    'ru': 'Введите ваше полное имя',
    'sk': 'Tu zadajte svoje celé meno',
    'sl': 'Sem vnesite svoje polno ime',
    'cs': 'Zde napište své celé jméno'
  },
  'name-tooltip': {
    'en': 'Please type a valid name, preferably your full name',
    'el': 'Παρακαλούμε εισάγετε έγκυρο όνομα, κατά προτίμηση ονοματεπώνυμο',
    'bg': 'Моля, въведете валидно име, за предпочитане пълното си име',
    'fr': 'Veuillez saisir un nom valide, de préférence votre nom et prénom',
    'de': 'Bitte einen gültigen Namen angeben, vorzugshalber Ihren vollständigen Namen.',
    'de-at': 'Bitte einen gültigen Namen angeben, vorzugshalber Ihren vollständigen Namen.',
    'es': 'Escriba un nombre válido, preferiblemente su nombre completo',
    'it': 'Inserire un nominativo valido, preferibilmente nome e cognome completo',
    'hr': 'Unesite važeće ime, po mogućnosti ime i prezime',
    'hu': 'Kérjük, egy érvényes nevet adjon meg, lehetőleg a teljes nevét',
    'pl': 'Wpisz prawidłową wartość, najlepiej imię i nazwisko',
    'pt': 'Introduza um nome válido, de preferência, o nome completo',
    'ro': 'Vă rugăm să tastați un nume valid, de preferință numele dvs. complet',
    'ru': 'Введите действительное имя, предпочтительно полное имя',
    'sk': 'Zadajte platné meno, ideálne svoje celé meno',
    'sl': 'Prosimo, vnesite veljavno ime, po možnosti svoje polno ime',
    'cs': 'Zadejte prosím platné jméno, nejlépe celé jméno'
  },
  'position': {
    'en': 'Position',
    'el': 'Θέση',
    'bg': 'Положение',
    'fr': 'Poste',
    'de': 'Stellung',
    'de-at': 'Stellung',
    'es': 'Puesto',
    'it': 'Posto',
    'hr': 'Mjesto',
    'hu': 'Munkakör',
    'pl': 'Stanowisko',
    'pt': 'Cargo',
    'ro': 'Loc',
    'ru': 'Место',
    'sk': 'Pozícia',
    'sl': 'Mesto',
    'cs': 'Pozice'
  },
  'position-placeholder': {
    'en': 'Type your role here if applicable',
    'el': 'Εισάγετε τον εταιρικό σας ρόλο αν υπάρχει',
    'bg': 'Въведете ролята си тук, ако е приложимо',
    'fr': 'Tapez votre rôle ici le cas échéant',
    'de': 'Geben Sie hier ggf. Ihre Rolle ein',
    'de-at': 'Geben Sie hier ggf. Ihre Rolle ein',
    'es': 'Escriba su función aquí si corresponde',
    'it': 'Digita qui il tuo ruolo se applicabile',
    'hr': 'Ovdje upišite svoju ulogu ako je primjenjivo',
    'hu': 'Írja be ide szerepét, ha van',
    'pl': 'Wpisz tutaj swoją rolę, jeśli dotyczy',
    'pt': 'Digite sua função aqui, se aplicável',
    'ro': 'Introduceți rolul dvs. aici, dacă este cazul',
    'ru': 'Введите здесь свою роль, если применимо',
    'sk': 'Ak je to možné, zadajte sem svoju rolu',
    'sl': 'Tukaj vnesite svojo vlogo, če je primerno',
    'cs': 'Pokud je to možné, zadejte svou roli'
  },
  'country': {
    'en': 'Country',
    'el': 'Χώρα',
    'bg': 'Страна',
    'fr': 'Pays',
    'de': 'Land',
    'de-at': 'Land',
    'es': 'País',
    'it': 'Nazione',
    'hr': 'Zemlja',
    'hu': 'Ország',
    'pl': 'Kraj',
    'pt': 'País',
    'ro': 'Țară',
    'ru': 'Страна',
    'sk': 'Krajina',
    'sl': 'Država',
    'cs': 'Země'
  },
  'country-placeholder': {
    'en': 'Select the country where you are located',
    'el': 'Επιλέξτε την χώρα τοποθεσίας σας',
    'bg': 'Изберете държавата си на местоположение',
    'fr': 'Sélectionner votre pays de localisation',
    'de': 'Geben Sie Ihr Standortland ein',
    'de-at': 'Geben Sie Ihr Standortland ein',
    'es': 'Seleccionar su país de ubicación',
    'it': 'Selezionare il tuo paese di posizione',
    'hr': 'Odaberite svoju zemlju lokacije',
    'hu': 'Válasszon meg a tartózkodási helyének országát',
    'pl': 'Wybierz swój kraj lokalizacji',
    'pt': 'Selecione seu país de localização',
    'ro': 'Selectați țara de locație',
    'ru': 'Выберите вашу страну нахождения',
    'sk': 'Vyberte krajinu, kde sa nachádzate',
    'sl': 'Izberite svojo državo lokacije',
    'cs': 'Vyberte zemi, kde se nacházíte'
  },
  'country-biz-network': {
    'en': 'BIZ Network',
    'el': 'Δίκτυο BIZ',
    'bg': 'BIZ мрежа',
    'fr': 'Réseau BIZ',
    'de': 'BIZ-Netzwerk',
    'de-at': 'BIZ-Netzwerk',
    'es': 'Red BIZ',
    'it': 'Rete BIZ',
    'hr': 'BIZ mreža',
    'hu': 'BIZ hálózat',
    'pl': 'Sieć BIZ',
    'pt': 'Rede BIZ',
    'ro': 'Rețeaua BIZ',
    'ru': 'Сеть BIZ',
    'sk': 'Sieť BIZ',
    'sl': 'BIZ omrežje',
    'cs': 'Síť BIZ'
  },
  'country-other': {
    'en': 'Other',
    'el': 'Άλλη',
    'fr': 'Autre',
    'de': 'Anderes',
    'de-at': 'Anderes',
    'es': 'Otro',
    'it': 'Altro',
    'hr': 'Druga',
    'hu': 'Másik',
    'pl': 'Inne',
    'pt': 'Outro',
    'ro': 'Alta',
    'ru': 'Другая',
    'sk': 'Iná',
    'sl': 'Druga',
    'cs': 'Jiná země'
  },
  'email': {
    'en': 'Email',
    'el': 'Email',
    'bg': 'Имейл',
    'fr': 'E-mail',
    'de': 'E-mail',
    'de-at': 'E-mail',
    'es': 'Correo electrónico',
    'it': 'Email',
    'hr': 'E-pošta',
    'hu': 'E-mail',
    'pl': 'E-mail',
    'pt': 'E-mail',
    'ro': 'E-mail',
    'ru': 'Электронная почта',
    'sk': 'E-mail',
    'sl': 'E-pošta',
    'cs': 'E-mail'
  },
  'email-placeholder': {
    'en': 'Type your email address here',
    'el': 'Εισάγετε τη διεύθυνση email σας',
    'bg': 'Въведете имейл адреса си тук',
    'fr': 'Saisissez votre adresse e-mail ici',
    'de': 'Bitte hier Ihre E-Mail-Adresse eingeben',
    'de-at': 'Bitte hier Ihre E-Mail-Adresse eingeben',
    'es': 'Escriba aquí su dirección de correo electrónico',
    'it': 'Inserire il proprio indirizzo email',
    'hr': 'Ovdje unesite svoju adresu e-pošte',
    'hu': 'Ide írja be az e-mail címét',
    'pl': 'Wpisz tutaj swój adres e-mail',
    'pt': 'Introduza aqui o endereço de e-mail',
    'ro': 'Tastați adresa dvs. de e-mail aici',
    'ru': 'Введите адрес вашей электронной почты',
    'sk': 'Tu zadajte svoju e-mailovú adresu',
    'sl': 'Sem vnesite svoj e-poštni naslov',
    'cs': 'Zde zadejte svou e-mailovou adresu'
  },
  'email-tooltip': {
    'en': 'Please type a valid email address',
    'el': 'Παρακαλούμε είσαγετε έγκυρη διεύθυνση email',
    'bg': 'Моля, въведете валиден имейл адрес',
    'fr': 'Veuillez saisir une adresse e-mail valide',
    'de': 'Bitte eine gültige E-Mail-Adresse eingeben',
    'de-at': 'Bitte eine gültige E-Mail-Adresse eingeben',
    'es': 'Escriba una dirección de correo electrónico válida',
    'it': 'Inserire un indirizzo email valido',
    'hr': 'Unesite važeću adresu e-pošte',
    'hu': 'Kérjük, egy érvényes e-mail címet adjon meg',
    'pl': 'Wpisz prawidłowy adres e-mail',
    'pt': 'Introduza um endereço de e-mail válido',
    'ro': 'Vă rugăm să tastați o adresă de e-mail validă',
    'ru': 'Введите рабочий адрес электронной почты',
    'sk': 'Zadajte platnú e-mailovú adresu',
    'sl': 'Prosimo, vnesite veljaven e-poštni naslov',
    'cs': 'Zadejte platnou e-mailovou adresu'
  },
  'phone': {
    'en': 'Phone',
    'el': 'Τηλέφωνο',
    'bg': 'Телефон',
    'fr': 'Téléphone',
    'de': 'Telefon',
    'de-at': 'Telefon',
    'es': 'Teléfono',
    'it': 'Telefono',
    'hr': 'Telefon',
    'hu': 'Telefon',
    'pl': 'Telefon',
    'pt': 'Telefone',
    'ro': 'Telefon',
    'ru': 'Телефон',
    'sk': 'Telefón',
    'sl': 'Telefon',
    'cs': 'Telefon'
  },
  'phone-placeholder': {
    'en': 'Type here for further contact',
    'el': 'Εισάγετε για διευκόλυνση της επικοινωνίας',
    'bg': 'Въведете тук допълнителна опция за връзка',
    'fr': 'Saisissez ici toute coordonnée de contact supplémentaire',
    'de': 'Bitte hier eine gültige Telefonnummer für weitere Kontaktaufnahme eingeben',
    'de-at': 'Bitte hier eine gültige Telefonnummer für weitere Kontaktaufnahme eingeben',
    'es': 'Escriba aquí para más contacto',
    'it': 'Inserire altri recapiti',
    'hr': 'Upišite za daljnje kontaktiranje',
    'hu': 'Ide írja be a további kapcsolattartási adatokat',
    'pl': 'Wpisz tutaj dodatkowe dane kontaktowe',
    'pt': 'Introduza aqui para contactos adicionais',
    'ro': 'Tastați aici pentru contactare ulterioară',
    'ru': 'Введите, чтобы с вами можно было связаться в дальнейшем',
    'sk': 'Tu zadajte ďalší kontakt',
    'sl': 'Vnesite tukaj za nadaljnji kontakt',
    'cs': 'Zadejte zde pro další kontakt'
  },
  'phone-tooltip': {
    'en': 'Please type a valid phone number, or leave blank',
    'el': 'Παρακαλούμε είτε εισάγετε έγκυρο αριθμό τηλεφώνου, είτε αφήστε κενό',
    'bg': 'Моля, въведете валиден телефонен номер или оставете полето празно',
    'fr': 'Veuillez saisir un numéro de téléphone valide, ou laisser le champ vide',
    'de': 'Bitte eine gültige Telefonnummer eingeben oder leer lassen',
    'de-at': 'Bitte eine gültige Telefonnummer eingeben oder leer lassen',
    'es': 'Escriba un número de teléfono válido o déjelo en blanco',
    'it': 'Inserire un numero di telefono valido o lasciare in bianco',
    'hr': 'Unesite važeći telefonski broj ili ostavite prazno',
    'hu': 'Kérjük, egy érvényes telefonszámot adjon meg, vagy hagyja a mezőt üresen',
    'pl': 'Wpisz prawidłowy numer telefonu lub pozostaw puste pole',
    'pt': 'Introduza um número de telefone válido, ou deixe em branco',
    'ro': 'Vă rugăm să tastați un număr de telefon valid, sau lăsați necompletat',
    'ru': 'Введите действующий номер телефона или оставьте поле пустым ',
    'sk': 'Zadajte platné telefónne číslo alebo nechajte pole prázdne',
    'sl': 'Prosimo, vnesite veljavno telefonsko številko ali pustite prazno',
    'cs': 'Zadejte platné telefonní číslo nebo nechte prázdné'
  },
  'phone-tooltip-required': {
    'en': 'Please type a valid phone number',
    'el': 'Παρακαλούμε είτε εισάγετε έγκυρο αριθμό τηλεφώνου',
    'bg': 'Моля, въведете валиден телефонен номер',
    'fr': 'Veuillez saisir un numéro de téléphone valide',
    'de': 'Bitte eine gültige Telefonnummer eingeben',
    'de-at': 'Bitte eine gültige Telefonnummer eingeben',
    'es': 'Escriba un número de teléfono válido',
    'it': 'Inserire un numero di telefono valido',
    'hr': 'Unesite važeći telefonski broj',
    'hu': 'Kérjük, egy érvényes telefonszámot adjon meg',
    'pl': 'Wpisz prawidłowy numer telefonu',
    'pt': 'Introduza um número de telefone válidoo',
    'ro': 'Vă rugăm să tastați un număr de telefon valid',
    'ru': 'Введите действующий номер телефона',
    'sk': 'Zadajte platné telefónne číslo',
    'sl': 'Prosimo, vnesite veljavno telefonsko številko',
    'cs': 'Zadejte platné telefonní číslo'
  },
  'company-name': {
    'en': 'Company Name',
    'el': 'Ονομασία Εταιρείας',
    'bg': 'Име на дружеството',
    'fr': 'Nom de l’entreprise',
    'de': 'Firmenname',
    'de-at': 'Firmenname',
    'es': 'Nombre de la empresa',
    'it': 'Nome della società',
    'hr': 'Naziv poduzeća',
    'hu': 'Vállalat neve',
    'pl': 'Nazwa firmy',
    'pt': 'Nome da empresa',
    'ro': 'Numele companiei',
    'ru': 'Название компании',
    'sk': 'Názov spoločnosti',
    'sl': 'Ime podjetja',
    'cs': 'Název společnosti'
  },
  'company-name-placeholder': {
    'en': 'Leave blank if not applicable',
    'el': 'Αφήστε κενό αν είστε ιδιώτης',
    'bg': 'Оставете полето празно, ако не е приложимо',
    'fr': 'Laisser le champ vide si sans objet',
    'de': 'Leer lassen, falls nichtzutreffend',
    'de-at': 'Leer lassen, falls nichtzutreffend',
    'es': 'Dejar en blanco si no es aplicable',
    'it': 'Lasciare in bianco se non è pertinente',
    'hr': 'Ostavite prazno ako nije primjenjivo',
    'hu': 'Ha nem alkalmazható, hagyja üresen',
    'pl': 'Pozostaw puste pole, jeśli nie dotyczy',
    'pt': 'Deixe em branco se não se aplicar',
    'ro': 'Lăsați necompletat, dacă nu este cazul',
    'ru': 'Оставьте поле пустым, если не применимо',
    'sk': 'Ak to nie je relevantné, nechajte pole prázdne',
    'sl': 'Pustite prazno, če se ne uporablja',
    'cs': 'Ponechejte prázdné, pokud není relevantní'
  },
  'consignment-code': {
    'en': 'Tracking Number',
    'el': 'Αριθμός Αποστολής',
    'bg': 'Проследяващ номер',
    'fr': 'Numéro de suivi',
    'de': 'Tracking-Nr.',
    'de-at': 'Tracking-Nr.',
    'es': 'Número de seguimiento',
    'it': 'Numero di tracciamento',
    'hr': 'Broj za praćenje',
    'hu': 'Nyomonkövetési szám',
    'pl': 'Numer śledzenia',
    'pt': 'Número de seguimento',
    'ro': 'Număr de urmărire',
    'ru': 'Номер отслеживания',
    'sk': 'Sledovacie číslo',
    'sl': 'Številka za sledenje',
    'cs': 'Sledovací číslo'
  },
  'consignment-code-placeholder': {
    'en': 'Type if your query concerns a shipment',
    'el': 'Εισάγετε αν το αίτημά σας αφορά αποστολή',
    'bg': 'Въведете го, ако запитването Ви се отнася за пратка',
    'fr': 'Complétez si votre recherche concerne un envoi',
    'de': 'Bitte ausfüllen, wenn Ihre Anfrage eine Sendung betrifft',
    'de-at': 'Bitte ausfüllen, wenn Ihre Anfrage eine Sendung betrifft',
    'es': 'Escriba si su consulta hace referencia a un envío',
    'it': 'Compilare se la vostra richiesta riguarda una spedizione',
    'hr': 'Upišite ako se vaš upit odnosi na pošiljku',
    'hu': 'Írja be, ha a kérése egy szállítmányra vonatkozik',
    'pl': 'Wpisz, jeśli zapytanie dotyczy wysyłki',
    'pt': 'Introduza aqui a sua consulta relativamente a um envio',
    'ro': 'Tastați dacă solicitarea dvs. se referă la o expediere',
    'ru': 'Введите, если делаете запрос по поводу отгрузки',
    'sk': 'Zadajte číslo, ak sa dopyt týka zásielky',
    'sl': 'Vnesite, če se vaše vprašanje nanaša na pošiljko',
    'cs': 'Zadejte, pokud se váš dotaz týká zásilky'
  },
  'industry': {
    'en': 'Industry',
    'el': 'Τομέας',
    'bg': 'Отрасъл',
    'fr': 'Secteur',
    'de': 'Branche',
    'de-at': 'Branche',
    'es': 'Industria',
    'it': 'Industria',
    'hr': 'Industrija',
    'hu': 'Iparág',
    'pl': 'Branża',
    'pt': 'setor',
    'ro': 'Industrie',
    'ru': 'Отрасль',
    'sk': 'Odvetvie',
    'sl': 'Industrija',
    'cs': 'Odvětví'
  },
  'message': {
    'en': 'Message',
    'el': 'Μήνυμα',
    'bg': 'Съобщение',
    'fr': 'Message',
    'de': 'Nachricht',
    'de-at': 'Nachricht',
    'es': 'Mensaje',
    'it': 'Messaggio',
    'hr': 'Preostalo',
    'hu': 'Üzenet',
    'pl': 'Wiadomość',
    'pt': 'Mensagem',
    'ro': 'Mesaj',
    'ru': 'Сообщение',
    'sk': 'Správa',
    'sl': 'Sporočilo',
    'cs': 'Zpráva'
  },
  'message-characters-left': {
    'en': 'left',
    'el': 'απομένουν',
    'bg': 'оставащи',
    'fr': 'restant',
    'de': 'übrig',
    'de-at': 'übrig',
    'es': 'izquierda',
    'it': 'rimasto',
    'hr': 'poruka',
    'hu': 'maradt',
    'pl': 'pozostało',
    'pt': 'resta',
    'ro': 'stânga',
    'ru': 'оставлен',
    'sk': 'zostáva',
    'sl': 'levo',
    'cs': 'zbývá'
  },
  'message-characters-min': {
    'en': 'Min. characters',
    'el': 'Ελάχιστοι χαρακτήρες',
    'bg': 'Мин. брой знаци',
    'fr': 'Nb minimum de caractères',
    'de': 'Mindestbuchstabenzahl',
    'de-at': 'Mindestbuchstabenzahl',
    'es': 'Caracteres mínimos',
    'it': 'N. min. caratteri',
    'hr': 'Dosegnut minimalan',
    'hu': 'Min. karakterszám',
    'pl': 'Min. liczba znaków',
    'pt': 'Mín. caracteres',
    'ro': 'Caractere minime',
    'ru': 'Мин. кол-во символов',
    'sk': 'Min. počet znakov',
    'sl': 'Min. št. znakov',
    'cs': 'Min. počet znaků'
  },
  'message-characters-reached': {
    'en': 'reached',
    'el': 'συμπληρώθηκαν',
    'bg': 'достигнати',
    'fr': 'atteint',
    'de': 'erreicht',
    'de-at': 'erreicht',
    'es': 'alcanzado',
    'it': 'raggiunto',
    'hr': 'broj znakova',
    'hu': 'elérve',
    'pl': 'osiągnięto limit',
    'pt': 'atingido',
    'ro': 'complet',
    'ru': 'достигнут',
    'sk': 'bol dosiahnutý',
    'sl': 'doseženo',
    'cs': 'dosažen'
  },
  'message-placeholder': {
    'en': 'Type in as much detail as possible to enable us to serve your request with speed and efficiency...',
    'el': 'Είσαγετε όσο το δυνατόν περισσότερη λεπτομέρεια ώστε να εξυπηρετήσουμε το αίτημά σας με ταχύτητα και αποτελεσματικότητα...',
    'bg': 'Въведете възможно най-много подробности, за да ни помогнете да отговорим на запитването Ви бързо и ефикасно…',
    'fr': 'Saisissez le plus de détails possible pour nous permettre de répondre à votre demande avec rapidité et efficacité...',
    'de': 'Geben Sie so viele Details wie möglich an, damit wir Ihre Anfrage schnell und effizient bearbeiten können…',
    'de-at': 'Geben Sie so viele Details wie möglich an, damit wir Ihre Anfrage schnell und effizient bearbeiten können...',
    'es': 'Escribir tantos detalles como sea posible para que podamos atender su solicitud con rapidez y eficiencia ...',
    'it': 'Inserire il maggior numero di dettagli possibile per consentirci di soddisfare la vostra richiesta con velocità ed efficienza...',
    'hr': 'Upišite što više informacija kako bismo mogli brzo i djelotvorno riješiti vaš zahtjev...',
    'hu': 'Írja be a lehető legrészletesebben, hogy kérését gyorsan és hatékonyan tudjuk kiszolgálni...',
    'pl': 'Wpisz jak najwięcej szczegółów, aby umożliwić nam szybkie i skuteczne rozpatrzenie Twojego zapytania…',
    'pt': 'Introduza o máximo de detalhes possível para que possamos atender o seu pedido de forma rápida e eficiente...',
    'ro': 'Introduceți cât mai multe detalii posibil pentru a ne permite să răspundem cererii dvs. cu rapiditate și eficiență…',
    'ru': 'Чтобы мы могли оперативно и точно обработать ваш запрос, введите максимально подробную информацию…',
    'sk': 'Zadajte čo najviac podrobností, aby sme mohli vašu požiadavku rýchlo a efektívne vybaviť...',
    'sl': 'Vnesite čim več podrobnosti, da bomo lahko hitro in učinkovito izpolnili vaše zahteve...',
    'cs': 'Zadejte co nejvíce podrobností, abychom mohli váš požadavek vyřídit rychle a efektivně…'
  },
  'declaration': {
    'en': 'I accept that my personal data will be handled solely for the purpose of processing my enquiry and the provision of marketing material, in full compliance with the BIZ Privacy Policy and GDPR regulations.',
    'el': 'Δέχομαι ότι τα προσωπικά μου δεδομένα θα διατεθούν αποκλειστικά για την επεξεργασία του αιτήματός μου και για προωθητικές ενέργειες, σε πλήρη συμμόρφωση με την Πολιτική Απορρήτου της BIZ και τη ρύθμιση GDPR.',
    'bg': 'Съгласен/а съм личната ми информация да се използва единствено с цел обработка на запитването ми и предоставяне на маркетингови материали в пълно съответствие с Декларацията за поверителност на BIZ и Общия регламент относно защитата на данните (ОРЗД).',
    'fr': "J'accepte que mes données personnelles soient traitées uniquement dans le but de donner suite à ma demande et de fournir du matériel marketing,conformément à la politique de confidentialité de BIZ et au règlement RGPD",
    'de': 'Ich erkläre mich damit einverstanden, dass meine persönlichen Daten ausschließlich zum Zweck der Bearbeitung meiner Anfrage und der Bereitstellung von Marketingmaterial in voller Übereinstimmung mit der BIZ-Datenschutzbestimmungen und den Vorschriften der DSGVO gespeichert werden.',
    'de-at': 'Ich erkläre mich damit einverstanden, dass meine persönlichen Daten ausschließlich zum Zweck der Bearbeitung meiner Anfrage und der Bereitstellung von Marketingmaterial in voller Übereinstimmung mit der BIZ-Datenschutzbestimmungen und den Vorschriften der DSGVO gespeichert werden.',
    'es': 'Acepto el tratamiento de mis datos personales con el fin de procesar mi consulta y proporcionarmaterial de marketing, en total cumplimiento con la política de privacidad de BIZ y las regulaciones de la RGPD.',
    'it': "Accetto che i miei dati personali vengano trattati esclusivamente allo scopo di elaborare la mia richiesta e per la fornitura di articoli di marketing, nel pieno rispetto dell'Informativa sulla Privacy di BIZ e del Regolamento Generale sulla Protezione dei Dati Personali (GDPR).",
    'hr': 'Slažem se da se moji osobni podaci upotrebljavaju isključivo u svrhu obrade mog upita i slanja marketinških materijala u skladu s BIZ-ovim pravilima o zaštiti privatnosti i propisima uredbe GDPR.',
    'hu': 'Elfogadom, hogy személyes adataimat kizárólag a kérésem feldolgozása és a marketinganyagok biztosítása céljából használják fel, a BIZ adatvédelmi szabályzatának és a GDPR-előírásoknak teljes mértékben.',
    'pl': 'Akceptuję, że moje dane osobowe będą przetwarzane wyłącznie w celu rozpatrzenia mojego zapytania i dostarczania materiałów marketingowych, przy zachowaniu pełnej zgodności z polityką prywatności BIZ i postanowieniami RODO.',
    'pt': 'Aceito que os meus dados pessoais sejam tratados apenas para fins de processamento do meu pedido e para o fornecimento de material de marketing, em total conformidade com a Política de Privacidade da BIZ e do RGPD.',
    'ro': 'Accept că datele mele cu caracter personal vor fi gestionate exclusiv în scopul procesării solicitării mele și furnizării de materiale de marketing, în deplină conformitate cu Politica de confidențialitate BIZ și reglementările GDPR.',
    'ru': 'Я соглашаюсь с тем, что мои персональные данные будут обрабатываться исключительно в целях обработки моего запроса и предоставления маркетинговых материалов в строгом соответствии с Политикой конфиденциальности компании BIZ и положениями Общего регламента по защите данных.',
    'sk': 'Súhlasím, že sa moje osobné údaje spracujú výhradne na účely vybavenia môjho dopytu a poskytnutie marketingových materiálov v plnom súlade s pravidlami ochrany osobných údajov spoločnosti BIZ a predpismi v zmysle nariadenia GDPR.',
    'sl': 'Strinjam se, da se bodo moji osebni podatki uporabljali izključno za namene obdelave moje poizvedbe in zagotavljanja tržnega gradiva v popolni skladnosti s politiko zasebnosti BIZ in predpisi GDPR.',
    'cs': 'Souhlasím s tím, že mé osobní údaje budou zpracovávány výhradně pro účely zpracování mé poptávky a poskytování marketingových materiálů, a to v plném souladu se zásadami ochrany osobních údajů společnosti BIZ a nařízeními GDPR.'
  },
  'select': {
    'en': 'Select',
    'el': 'Επιλέξτε',
    'bg': 'Избор',
    'fr': 'Sélectionner',
    'de': 'Auswählen',
    'de-at': 'Auswählen',
    'es': 'Seleccionar',
    'it': 'Selezionare',
    'hr': 'Odaberi',
    'hu': 'Válasszon',
    'pl': 'Wybierz',
    'pt': 'Selecionar',
    'ro': 'Selectați ',
    'ru': 'Выбрать',
    'sk': 'Vyberte',
    'sl': 'Izberite',
    'cs': 'Vyberte'
  },
  'select-one': {
    'en': 'Select at least one',
    'el': 'Επιλέξτε τουλάχιστον μία',
    'bg': 'Изберете поне една опция',
    'fr': 'Sélectionner au moins un(e)',
    'de': 'Wenigstens einen Punkt auswählen',
    'de-at': 'Wenigstens einen Punkt auswählen',
    'es': 'Seleccionar al menos uno',
    'it': 'Selezionare almeno una voce',
    'hr': 'Odaberite najmanje jedan',
    'hu': 'Válasszon legalább egyet',
    'pl': 'Wybierz co najmniej jedną opcję',
    'pt': 'Selecione pelo menos uma opção',
    'ro': 'Selectați cel puțin una',
    'ru': 'Выберите не менее одного',
    'sk': 'Vyberte aspoň jednu možnosť',
    'sl': 'Izberite vsaj enega',
    'cs': 'Vyberte alespoň jednu možnost'
  },
  'interested-in': {
    'en': 'I am interested in',
    'el': 'Ενδιαφέρομαι για',
    'bg': 'Интересувам се от',
    'fr': 'Je suis intéressé(e)',
    'de': 'Ich interessiere mich für',
    'de-at': 'Ich interessiere mich für',
    'es': 'Tengo interés en',
    'it': 'Sono interessato a',
    'hr': 'Zainteresiran/zainteresirana sam za',
    'hu': 'Érdekel',
    'pl': 'Przedmiot zainteresowania',
    'pt': 'Estou interessado(a) em',
    'ro': 'Sunt interesat de',
    'ru': 'Я интересуюсь',
    'sk': 'Mám záujem o',
    'sl': 'Zanimam se za',
    'cs': 'Zajímá mne'
  },
  'shipping-delivery': {
    'en': 'Shipping and delivery',
    'el': 'Μεταφορά και παράδοση',
    'bg': 'Изпращане и доставка',
    'fr': 'Expédition et livraison',
    'de': 'Versand und Lieferung',
    'de-at': 'Versand und Lieferung',
    'es': 'Envío y entrega',
    'it': 'Spedizione e consegna',
    'hr': 'Otprema i dostava',
    'hu': 'Szállítás és kézbesítés',
    'pl': 'Wysyłka i dostawa',
    'pt': 'Envio e entrega',
    'ro': 'Expediere și livrare',
    'ru': 'Отгрузка и поставка',
    'sk': 'Zasielanie a doručovanie',
    'sl': 'Odprema in dostava',
    'cs': 'Přeprava a doručení'
  },
  'product-storage-fulfillment': {
    'en': 'Product storage and order fulfillment',
    'el': 'Αποθήκευση εμπορευμάτων και εκπλήρωση',
    'bg': 'Складиране на продукти и изпълнение на заявки',
    'fr': 'Stockage des produits et exécution des commandes',
    'de': 'Lagerung und Auftragsabwicklung',
    'de-at': 'Lagerung und Auftragsabwicklung',
    'es': 'Almacenamiento de productos y cumplimiento de pedidos',
    'it': 'Deposito prodotti ed evasione ordini',
    'hr': 'Skladištenje proizvoda i isporuka narudžbe',
    'hu': 'Terméktárolás és megrendelések teljesítése',
    'pl': 'Przechowywanie produktów i realizacja zamówień',
    'pt': 'Armazenamento de produtos e entrega de encomendas',
    'ro': 'Depozitarea produsului și îndeplinirea comenzii',
    'ru': 'Хранение продукции и выполнение заказа',
    'sk': 'Skladovanie výrobkov a plnenie objednávok',
    'sl': 'Skladiščenje izdelkov in izpolnjevanje naročil',
    'cs': 'Skladování výrobků a plnění objednávek'
  },
  'specialist-services': {
    'en': 'Specialist services',
    'el': 'Εξειδικευμένες υπηρεσίες',
    'bg': 'Специализирани услуги',
    'fr': 'Services spécialisés',
    'de': 'Fachdienste',
    'de-at': 'Fachdienste',
    'es': 'Servicios especializados',
    'it': 'Servizi specializzati',
    'hr': 'Specijalističke usluge',
    'hu': 'Speciális szolgáltatások',
    'pl': 'Usługi specjalistyczne',
    'pt': 'Serviços especializados',
    'ro': 'Servicii de specialitate',
    'ru': 'Услуги специалиста',
    'sk': 'Špecializované služby',
    'sl': 'Specialistične storitve',
    'cs': 'Specializované služby'
  },
  'cash-on-delivery': {
    'en': 'Cash on delivery (COD)',
    'el': 'Αντικαταβολή (COD)',
    'bg': 'Наложен платеж',
    'fr': 'Paiement à la livraison',
    'de': 'Nachnahme (COD)',
    'de-at': 'Nachnahme (COD)',
    'es': 'Contra reembolso (COD)',
    'it': 'Pagamento alla Consegna (COD)',
    'hr': 'Plaćanje pouzećem (COD)',
    'hu': 'Készpénz utánvételes fizetés (COD)',
    'pl': 'Płatność przy odbiorze',
    'pt': 'Pagamento contra entrega',
    'ro': 'Plata în numerar la livrare (COD)',
    'ru': 'Оплата при доставке (COD)',
    'sk': 'Dobierka',
    'sl': 'Plačilo po povzetju (COD)',
    'cs': 'Dodání na dobírku (COD)'
  },
  'returns-management': {
    'en': 'Returns management',
    'el': 'Διαχείριση επιστροφών',
    'bg': 'Управление на връщанията',
    'fr': 'Gestion des retours',
    'de': 'Retourenmanagement',
    'de-at': 'Retourenmanagement',
    'es': 'Gestión de devoluciones',
    'it': 'Gestione dei resi',
    'hr': 'Upravljanje povratima',
    'hu': 'Visszatérítés kezelése',
    'pl': 'Zarządzanie zwrotami',
    'pt': 'Gestão de devoluções',
    'ro': 'Managementul returnărilor',
    'ru': 'Управление возвратом',
    'sk': 'Manažment vrátenia tovaru',
    'sl': 'Upravljanje vračil',
    'cs': 'Vrací řízení'
  },
  'delivery-countries': {
    'en': 'I would like to deliver in these countries',
    'el': 'Θα ήθελα παράδοση στις παρακάτω χώρες',
    'bg': 'Искам да доставям в следните държави',
    'fr': 'Je souhaite livrer dans ces pays',
    'de': 'Ich möchte meine Waren in folgende Länder senden',
    'de-at': 'Ich möchte meine Waren in folgende Länder senden',
    'es': 'Me gustaría entregar en estos países',
    'it': 'Vorrei consegnare in questi Paesi',
    'hr': 'Želio bih isporučivati ​​u tim državama',
    'hu': 'Szeretnék szállítani ezekbe az országokba',
    'pl': 'Interesuje mnie dostawa w następujących krajach',
    'pt': 'Gostaria de fazer entregas nestes países',
    'ro': 'Aș dori să livrez în aceste țări',
    'ru': 'Я хочу поставлять в эти страны',
    'sk': 'Chcem doručovať v týchto krajinách',
    'sl': 'Želim dostavljati v te države',
    'cs': 'Rád bych doručoval/a do těchto zemí'
  },
  'volume-estimate-orders': {
    'en': 'Total volume in orders / month',
    'el': 'Προβλεπόμενος όγκος σε παραγγελίες / μήνα',
    'bg': 'Общ брой поръчки/месец',
    'fr': 'Volume total de commandes / mois',
    'de': 'Gesamtvolumen Aufträge/Monat',
    'de-at': 'Gesamtvolumen Aufträge/Monat',
    'es': 'Volumen total en pedidos/mes',
    'it': 'Volume totale: ordini/mese',
    'hr': 'Ukupna količina u narudžbama/mjesec',
    'hu': 'Teljes rendelési mennyiség / hónap',
    'pl': 'Łączny wolumen (zamówienia/miesiąc)',
    'pt': 'Volume total em encomendas/mês',
    'ro': 'Volumul total de comenzi / lună',
    'ru': 'Общий объем в заказах / месяцах',
    'sk': 'Celkový objem v objednávkach/mesiac',
    'sl': 'Skupni obseg naročil/mesec',
    'cs': 'Celkový objem v objednávkách/měsíc'
  },
  'volume-estimate-shipments': {
    'en': 'Total volume in shipments / month',
    'el': 'Προβλεπόμενος όγκος σε αποστολές / μήνα',
    'bg': 'Общ брой пратки/месец',
    'fr': 'Volume total en expéditions / mois',
    'de': 'Gesamtvolumen Sendungen/Monat',
    'de-at': 'Gesamtvolumen Sendungen/Monat',
    'es': 'Volumen total en envíos/mes',
    'it': 'Volume totale: spedizioni/mese',
    'hr': 'Ukupna količina u pošiljkama/mjesec',
    'hu': 'Teljes szállítási mennyiség / hónap',
    'pl': 'Łączny wolumen (dostawy/miesiąc)',
    'pt': 'Volume total em envios/mês',
    'ro': 'Volumul total de transporturi / lună',
    'ru': 'Общий объем в отгрузках / месяцах',
    'sk': 'Celkový objem v zásielkach/mesiac',
    'sl': 'Skupna količina pošiljk/mesec',
    'cs': 'Celkový objem v dodávkách/měsíc'
  },
  'volume-estimate-placeholder': {
    'en': 'Select range estimate',
    'el': 'Επιλέξτε εκτιμήση κίνησης',
    'bg': 'Изберете прогнозно разстояние',
    'fr': 'Sélectionner la fourchette estimée',
    'de': 'Geschätze Größenordnung auswählen',
    'de-at': 'Geschätze Größenordnung auswählen',
    'es': 'Seleccionar estimación de rango',
    'it': 'Seleziona una stima del numero',
    'hr': 'Odaberite procjenu dosega',
    'hu': 'Válasszon egy tartománybecslést',
    'pl': 'Wybierz szacunkowy zakres',
    'pt': 'Selecione a estimativa de intervalo',
    'ro': 'Selectați intervalul estimat',
    'ru': 'Выбрать оценку в диапазоне значений',
    'sk': 'Vyberte odhad rozsahu',
    'sl': 'Izberite oceno obsega',
    'cs': 'Zvolte přibližný rozsah'
  },
  'parcel-size': {
    'en': 'Transport type',
    'el': 'Είδος μεταφοράς',
    'bg': 'Вид транспорт',
    'fr': 'Type de transport',
    'de': 'Transportart',
    'de-at': 'Transportart',
    'es': 'Tipo de transporte',
    'it': 'Tipo di trasporto',
    'hr': 'Vrsta prijevoza',
    'hu': 'Szállítás típusa',
    'pl': 'Rodzaj transportu',
    'pt': 'Tipo de transporte',
    'ro': 'Tipul de transport',
    'ru': 'Тип транспорта',
    'sk': 'Typ prepravy',
    'sl': 'Vrsta prevoza',
    'cs': 'Typ přepravy'
  },
  'parcel-size-placeholder': {
    'en': 'Specify size of goods',
    'el': 'Προσδιορίστε το μέγεθος',
    'bg': 'Посочете размера на стоките',
    'fr': 'Préciser la taille des marchandises',
    'de': 'Geben Sie die Größe der versendeten Güter an',
    'de-at': 'Geben Sie die Größe der versendeten Güter an',
    'es': 'Especificar el tamaño de las mercancías',
    'it': 'Specificare le dimensioni della merce',
    'hr': 'Navedite veličinu robe',
    'hu': 'Adja meg az áruk méretét',
    'pl': 'Określ rozmiar towarów',
    'pt': 'Especifique o tamanho das mercadorias',
    'ro': 'Specificați dimensiunea mărfurilor',
    'ru': 'Указать размер товаров',
    'sk': 'Zadajte veľkosť tovaru',
    'sl': 'Navedite velikost blaga',
    'cs': 'Zadejte rozměr zboží'
  },
  'small': {
    'en': 'Small Parcel',
    'el': 'Μικρό Δέμα',
    'bg': 'Малък пакет',
    'fr': 'Petit colis',
    'de': 'Kleines Paket',
    'de-at': 'Kleines Paket',
    'es': 'Paquete pequeño',
    'it': 'Pacchetto',
    'hr': 'Mali paket',
    'hu': 'Kisméretű csomag',
    'pl': 'Małe paczki',
    'pt': 'Embalagem pequena',
    'ro': 'Colet mic',
    'ru': 'Небольшая посылка',
    'sk': 'Malý balík',
    'sl': 'Majhen paket',
    'cs': 'Malý balíček'
  },
  'medium': {
    'en': 'Medium Box',
    'el': 'Κουτί μεσαίου μεγέθους',
    'bg': 'Кутия със среден размер',
    'fr': 'Carton moyen',
    'de': 'Mittleres Paket',
    'de-at': 'Mittleres Paket',
    'es': 'Caja mediana',
    'it': 'Scatola media',
    'hr': 'Srednja kutija',
    'hu': 'Közepes méretű doboz',
    'pl': 'Średnie paczki',
    'pt': 'Caixa média',
    'ro': 'Cutie medie',
    'ru': 'Средняя коробка',
    'sk': 'Stredne veľký balík',
    'sl': 'Srednja škatla',
    'cs': 'Střední krabice'
  },
  'large': {
    'en': 'Large Box',
    'el': 'Μεγάλο Κουτί',
    'bg': 'Кутия с голям размер',
    'fr': 'Grand carton',
    'de': 'Großes Paket',
    'de-at': 'Großes Paket',
    'es': 'Caja grande',
    'it': 'Scatola grande',
    'hr': 'Velika kutija',
    'hu': 'Nagyméretű doboz',
    'pl': 'Duże paczki',
    'pt': 'Caixa grande',
    'ro': 'Cutie mare',
    'ru': 'Большая коробка',
    'sk': 'Veľký balík',
    'sl': 'Velika škatla',
    'cs': 'Velká krabice'
  },
  'envelope': {
    'en': 'Envelope / Document',
    'el': 'Φάκελος / Έγγραφο',
    'bg': 'Плик/документ',
    'fr': 'Enveloppe / Document',
    'de': 'Umschlag / Dokumente',
    'de-at': 'Umschlag / Dokumente',
    'es': 'Sobre/Documento',
    'it': 'Busta / Documento',
    'hr': 'Omotnica/Dokument',
    'hu': 'Boríték / Dokumentum',
    'pl': 'Koperty/Dokumenty',
    'pt': 'Envelope/Documento',
    'ro': 'Plic / Document',
    'ru': 'Конверт / Документ',
    'sk': 'Obálka/dokument',
    'sl': 'Kuverta / dokument',
    'cs': 'Obálka/dokument'
  },
  'cargo': {
    'en': 'Cargo / Pallet',
    'el': 'Φορτίο / Παλέτα',
    'bg': 'Товарен превоз/Палет',
    'fr': 'Cargaison / Palette',
    'de': 'Cargo / Palette',
    'de-at': 'Cargo / Palette',
    'es': 'Carga/palé',
    'it': 'Trasporto merci / Pallet',
    'hr': 'Teret/paleta',
    'hu': 'Rakomány / Raklap',
    'pl': 'Kontener/paleta',
    'pt': 'Carga/Palete',
    'ro': 'Încărcătură / Palet ',
    'ru': 'Груз / паллета',
    'sk': 'Náklad/paleta',
    'sl': 'Tovor / Paleta',
    'cs': 'Náklad/paleta'
  },
  'other': {
    'en': 'Other',
    'el': 'Άλλο',
    'bg': 'Друго',
    'fr': 'Autre',
    'de': 'Anderes',
    'de-at': 'Anderes',
    'es': 'Otro',
    'it': 'Altro',
    'hr': 'Drugo',
    'hu': 'Egyéb',
    'pl': 'Inne',
    'pt': 'Outro',
    'ro': 'Altele',
    'ru': 'Другое',
    'sk': 'Iné',
    'sl': 'Drugo',
    'cs': 'Jiné'
  },
  'industry-placeholder': {
    'en': 'Type field of activity e.g. Fashion',
    'el': 'Εισάγετε δραστηριότητα π.χ. Μόδα',
    'bg': 'Въведете сферата на дейността, напр. мода',
    'fr': "Indiquer domaine d'activité, par Mode",
    'de': 'Geben Sie Ihre Branche an z.B. Mode',
    'de-at': 'Geben Sie Ihre Branche an z.B. Mode',
    'es': 'Escribir el campo de actividad, como Moda',
    'it': 'Inserire settore di attività, ad es. La moda',
    'hr': 'Vrsta djelatnosti, npr. Moda',
    'hu': 'Tevékenység típusa pl.: Divat',
    'pl': 'Wpisz obszar działalności, np. Moda',
    'pt': 'Introduza o setor de atividade, ex.: Moda',
    'ro': 'Introduceți domeniul de activitate, de ex. Modă',
    'ru': 'Введите область деятельности, например, Мода',
    'sk': 'Zadajte oblasť činnosti, napr. Móda',
    'sl': 'Vrsta področja dejavnosti npr. Moda',
    'cs': 'Typová oblast činnosti, např. Móda'
  },
  'warehouse-location': {
    'en': 'Fulfillment centre location',
    'el': 'Τοποθεσία κέντρου εκπλήρωσης',
    'bg': 'Местоположение на центъра за изпълнение',
    'fr': 'Emplacement du centre de distribution',
    'de': 'Standort des Fulfillment-Centers',
    'de-at': 'Standort des Fulfillment-Centers',
    'es': 'Ubicación del centro logístico',
    'it': 'Posizione del centro logistico',
    'hr': 'Lokacija centra za ispunjenje',
    'hu': 'Teljesítési központ helye',
    'pl': 'Lokalizacja centrum logistycznego',
    'pt': 'Localização do centro de atendimento',
    'ro': 'Locația centrului de livrare',
    'ru': 'Расположение фулфилмент-центра',
    'sk': 'Umiestnenie centra plnenia',
    'sl': 'Lokacija centra za izpolnitev',
    'cs': 'Umístění centra plnění'
  },
  'warehouse-location-placeholder': {
    'en': 'Indicate preferred storage location',
    'el': 'Σημειώστε την κύρια - επιθυμητή',
    'bg': 'Посочете предпочитаното местоположение за съхранение',
    'fr': "Indiquer l'emplacement de stockage souhaité",
    'de': 'Geben sie Ihr bevorzugtes Zwischenlager an',
    'de-at': 'Geben sie Ihr bevorzugtes Zwischenlager an',
    'es': 'Indicar la ubicación de almacenamiento preferida',
    'it': "Indicare l'ubicazione di deposito preferita",
    'hr': 'Navedite željenu lokaciju skladištenja',
    'hu': 'Jelölje ki az előnyben részesített raktár helyét',
    'pl': 'Wskaż preferowaną lokalizację przechowywania',
    'pt': 'Indique o local de armazenamento preferido',
    'ro': 'Indicați locația de stocare preferată',
    'ru': 'Укажите предпочитаемое место для хранения',
    'sk': 'Uveďte preferované miesto skladovania',
    'sl': 'Navedite prednostno lokacijo shranjevanja',
    'cs': 'Uveďte preferované místo skladování'
  },
  'BIZ-HQ': {
    'en': `${brandName} Athens`,
    'el': `${brandName} Αθήνα`,
    'bg': `${brandName} Атина`,
    'fr': `${brandName} Athènes`,
    'de': `${brandName} Athen`,
    'de-at': `${brandName} Athen`,
    'es': `${brandName} Atenas`,
    'it': `${brandName} Atene`,
    'hr': `${brandName} Atena`,
    'hu': `${brandName} Athén`,
    'pl': `${brandName} Ateny`,
    'pt': `${brandName} Atenas`,
    'ro': `${brandName} Atena`,
    'ru': `${brandName} Афины`,
    'sk': `${brandName} Atény`,
    'sl': `${brandName} Atene`,
    'cs': `${brandName} Atény`
  },
  'DE-LZ': {
    'en': `${brandName} Leipzig`,
    'el': `${brandName} Λειψία`,
    'bg': `${brandName} Лайпциг`,
    'fr': `${brandName} Leipzig`,
    'de': `${brandName} Leipzig`,
    'de-at': `${brandName} Leipzig`,
    'es': `${brandName} Leipzig`,
    'it': `${brandName} Lipsia`,
    'hr': `${brandName} Leipzig`,
    'hu': `${brandName} Lipcse`,
    'pl': `${brandName} Lipsk`,
    'pt': `${brandName} Leipzig`,
    'ro': `${brandName} Leipzig`,
    'ru': `${brandName} Лейпциг`,
    'sk': `${brandName} Lipsko`,
    'sl': `${brandName} Leipzig`,
    'cs': `${brandName} Lipsko`
  },
  'ES-VL': {
    'en': `${brandName} Valencia`,
    'el': `${brandName} Βαλένθια`,
    'bg': `${brandName} Валенсия`,
    'fr': `${brandName} Valencia`,
    'de': `${brandName} Valencia`,
    'de-at': `${brandName} Valencia`,
    'es': `${brandName} Valencia`,
    'it': `${brandName} Valencia`,
    'hr': `${brandName} Valencia`,
    'hu': `${brandName} Valencia`,
    'pl': `${brandName} Walencja`,
    'pt': `${brandName} Valência`,
    'ro': `${brandName} Valencia`,
    'ru': `${brandName} Валенсия`,
    'sk': `${brandName} Valencia`,
    'sl': `${brandName} Valencija`,
    'cs': `${brandName} Valencie`
  },
  'BG-RU': {
    'en': `${brandName} Bulgaria`,
    'el': `${brandName} Βουλγαρία`,
    'bg': `${brandName} Румъния`,
    'fr': `${brandName} Roumanie`,
    'de': `${brandName} Rumänien`,
    'de-at': `${brandName} Rumänien`,
    'es': `${brandName} Rumania`,
    'it': `${brandName} Romania`,
    'hr': `${brandName} Rumunjska`,
    'hu': `${brandName} Románia`,
    'pl': `${brandName} Rumunia`,
    'pt': `${brandName} Romênia`,
    'ro': `${brandName} România`,
    'ru': `${brandName} Румыния`,
    'sk': `${brandName} Rumunsko`,
    'sl': `${brandName} Romunija`,
    'cs': `${brandName} Rumunsko`
  },
  'HU-NT': {
    'en': `${brandName} Budapest`,
    'el': `${brandName} Βουδαπέστη`,
    'bg': `${brandName} Будапеща`,
    'fr': `${brandName} Budapest`,
    'de': `${brandName} Budapest`,
    'de-at': `${brandName} Budapest`,
    'es': `${brandName} Budapest`,
    'it': `${brandName} Budapest`,
    'hr': `${brandName} Budimpešta`,
    'hu': `${brandName} Budapest`,
    'pl': `${brandName} Budapeszt`,
    'pt': `${brandName} Budapeste`,
    'ro': `${brandName} Budapesta`,
    'ru': `${brandName} Будапешт`,
    'sk': `${brandName} Budapešť`,
    'sl': `${brandName} Budimpešta`,
    'cs': `${brandName} Budapešť`
  },
  'IT-BZ': {
    'en': `${brandName} Bolzano`,
    'el': `${brandName} Μπολτσάνο`,
    'bg': `${brandName} Болцано`,
    'fr': `${brandName} Bolzano`,
    'de': `${brandName} Bozen`,
    'de-at': `${brandName} Bozen`,
    'es': `${brandName} Bolzano`,
    'it': `${brandName} Bolzano`,
    'hr': `${brandName} Bolzano`,
    'hu': `${brandName} Bolzano`,
    'pl': `${brandName} Bolzano`,
    'pt': `${brandName} Bolzano`,
    'ro': `${brandName} Bolzano`,
    'ru': `${brandName} Больцано`,
    'sk': `${brandName} Bolzano`,
    'sl': `${brandName} Bolzano`,
    'cs': `${brandName} Bolzano`
  },
  'RO-BZ': {
    'en': `${brandName} Romania`,
    'el': `${brandName} Ρουμανία`,
    'bg': `${brandName} Румъния`,
    'fr': `${brandName} Roumanie`,
    'de': `${brandName} Rumänien`,
    'de-at': `${brandName} Rumänien`,
    'es': `${brandName} Rumania`,
    'it': `${brandName} Romania`,
    'hr': `${brandName} Rumunjska`,
    'hu': `${brandName} Románia`,
    'pl': `${brandName} Rumunia`,
    'pt': `${brandName} Romênia`,
    'ro': `${brandName} România`,
    'ru': `${brandName} Румыния`,
    'sk': `${brandName} Rumunsko`,
    'sl': `${brandName} Romunija`,
    'cs': `${brandName} Rumunsko`
  },
  'CY-NI': {
    'en': `${brandName} Nicosia`,
    'el': `${brandName} Λευκωσία`,
    'bg': `${brandName} Никозия`,
    'fr': `${brandName} Nicosie`,
    'de': `${brandName} Nikosia`,
    'de-at': `${brandName} Nikosia`,
    'es': `${brandName} Nicosia`,
    'it': `${brandName} Nicosia`,
    'hr': `${brandName} Nikozija`,
    'hu': `${brandName} Nicosia`,
    'pl': `${brandName} Nikozja`,
    'pt': `${brandName} Nicósia`,
    'ro': `${brandName} Nicosia`,
    'ru': `${brandName} Никосия`,
    'sk': `${brandName} Nikózia`,
    'sl': `${brandName} Nikozija`,
    'cs': `${brandName} Nikósie`
  },
  'PT-MA': {
    'en': `${brandName} Portugal`,
    'el': `${brandName} Portugal`,
    'bg': `${brandName} Portugal`,
    'fr': `${brandName} Portugal`,
    'de': `${brandName} Portugal`,
    'de-at': `${brandName} Portugal`,
    'es': `${brandName} Portugal`,
    'it': `${brandName} Portugal`,
    'hr': `${brandName} Portugal`,
    'hu': `${brandName} Portugal`,
    'pl': `${brandName} Portugal`,
    'pt': `${brandName} Portugal`,
    'ro': `${brandName} Portugal`,
    'ru': `${brandName} Portugal`,
    'sk': `${brandName} Portugal`,
    'sl': `${brandName} Portugal`,
    'cs': `${brandName} Portugal`
  },
  'ES-CN': {
    'en': `${brandName} Canary Islands`,
    'el': `${brandName} Canary Islands`,
    'bg': `${brandName} Canary Islands`,
    'fr': `${brandName} Canary Islands`,
    'de': `${brandName} Canary Islands`,
    'de-at': `${brandName} Canary Islands`,
    'es': `${brandName} Canary Islands`,
    'it': `${brandName} Canary Islands`,
    'hr': `${brandName} Canary Islands`,
    'hu': `${brandName} Canary Islands`,
    'pl': `${brandName} Canary Islands`,
    'pt': `${brandName} Canary Islands`,
    'ro': `${brandName} Canary Islands`,
    'ru': `${brandName} Canary Islands`,
    'sk': `${brandName} Canary Islands`,
    'sl': `${brandName} Canary Islands`,
    'cs': `${brandName} Canary Islands`
  },
  'technologies': {
    'en': 'Technologies of interest',
    'el': 'Τεχνολογίες διασύνδεσης',
    'bg': 'Технологии, към които проявявате интерес',
    'fr': "Technologies d'intérêt",
    'de': 'Wichtige Technologien',
    'de-at': 'Wichtige Technologien',
    'es': 'Tecnologías de interés',
    'it': 'Tecnologie di interesse',
    'hr': 'Obuhvaćene tehnologije',
    'hu': 'Érdeklődési körnek megfelelő technológiák',
    'pl': 'Interesujące technologie',
    'pt': 'Tecnologias de interesse',
    'ro': 'Tehnologii de interes',
    'ru': 'Интуитивно-понятное приложение',
    'sk': 'Relevantné technológie',
    'sl': 'Zanimive tehnologije',
    'cs': 'Zajímavé technologie'
  },
  'desktop-app': {
    'en': 'User-friendly Application',
    'el': 'Φιλική προς τον χρήστη Εφαρμογή',
    'bg': 'Лесно за използване приложение',
    'fr': 'Application conviviale',
    'de': 'Anwenderfreundliche Anwendung',
    'de-at': 'Anwenderfreundliche Anwendung',
    'es': 'Aplicación fácil de usar',
    'it': 'Applicazione facile da usare',
    'hr': 'Aplikacija prilagođena korisnicima',
    'hu': 'Felhasználóbarát alkalmazás',
    'pl': 'Łatwa w obsłudze aplikacja',
    'pt': 'Aplicação simples de utilizar',
    'ro': 'Aplicație ușor de utilizat',
    'ru': 'Интересующие технологии',
    'sk': 'Používateľsky ústretová aplikácia',
    'sl': 'Uporabniku prijazna aplikacija',
    'cs': 'Uživatelsky přívětivá aplikace'
  },
  'web-services-api': {
    'en': 'Web Services / API',
    'el': 'Υπηρεσίες Web / API',
    'bg': 'Уеб услуги/Приложно-програмен интерфейс',
    'fr': 'Services Web / API',
    'de': 'Web-Service / API',
    'de-at': 'Web-Service / API',
    'es': 'Servicios web/API',
    'it': 'Servizi web / API',
    'hr': 'Web-servisi/API',
    'hu': 'Webszolgáltatások / API',
    'pl': 'Usługi sieciowe / API',
    'pt': 'Serviços web/API',
    'ro': 'Servicii Web / API',
    'ru': 'Службы Интернета / API',
    'sk': 'Webové služby/API',
    'sl': 'Spletne storitve / API',
    'cs': 'Webové služby / API'
  },
  'biz-link': {
    'en': 'eCommerce Integrations',
    'el': 'Αυτόματη ανάθεση από eCommerce',
    'bg': 'Интеграции за електронна търговия',
    'fr': 'Intégrations de commerce électronique',
    'de': 'eCommerce Integration',
    'de-at': 'eCommerce Integration',
    'es': 'Integraciones de comercio electrónico',
    'it': 'Integrazioni e-commerce',
    'hr': 'Integracije e-trgovine',
    'hu': 'e-kereskedelem integrációja',
    'pl': 'Integracja rozwiązań z zakresu handlu elektronicznego',
    'pt': 'Integrações de comércio eletrónico',
    'ro': 'Integrări eCommerce',
    'ru': 'Интеграция с инструментами электронной коммерции',
    'sk': 'Integrácie do elektronického obchodu',
    'sl': 'Integracije e-trgovine',
    'cs': 'Integrace elektronických obchodů'
  },
  'send-button': {
    'en': 'Send',
    'el': 'Αποστολή',
    'bg': 'Изпращане',
    'fr': 'Envoyer',
    'de': 'Absenden',
    'de-at': 'Absenden',
    'es': 'Enviar',
    'it': 'Inviare',
    'hr': 'Šalji',
    'hu': 'Küldés',
    'pl': 'Wyślij',
    'pt': 'Envio',
    'ro': 'Trimite',
    'ru': 'Отправить',
    'sk': 'Odoslať',
    'sl': 'Pošlji',
    'cs': 'Odeslat'
  },
  'tooltip-declaration': {
    'en': 'Please check the box to agree to our Privacy Policy before sending',
    'el': 'Παρακαλούμε επιλέξτε το πλαίσιο ώστε να αποδεχτείτε την Πολιτική Απορρήτου μας',
    'bg': 'Моля, поставете отметка в полето, за да приемете Декларацията ни за поверителност, преди да изпратите формуляра',
    'fr': "Veuillez cocher la case pour accepter notre politique de confidentialité avant d'envoyer",
    'de': 'Bitte vor Absenden das Kästchen ankreuzen, um unseren Datenschutzbestimmungen zuzustimmen.',
    'de-at': 'Bitte vor Absenden das Kästchen ankreuzen, um unseren Datenschutzbestimmungen zuzustimmen.',
    'es': 'Marcar la casilla para aceptar nuestra política de privacidad antes del envío',
    'it': 'Prima di inviare, selezionare la casella per accettare la nostra Informativa sulla Privacy',
    'hr': 'Prije slanja označite okvir ako se slažete s našim pravilima o zaštiti privatnosti',
    'hu': 'A küldés előtt jelölje be a négyzetet, hogy egyetért Adatvédelmi irányelveinkkel',
    'pl': 'Przed wysłaniem zaznacz pole wyboru potwierdzające akceptację naszej Polityki prywatności',
    'pt': 'Assinale a caixa para concordar com a nossa Política de Privacidade antes do envio',
    'ro': 'Vă rugăm să bifați caseta pentru a vă exprima acordul cu politica noastră de confidențialitate înainte de trimitere',
    'ru': 'Поставьте галочку, чтобы принять нашу Политику конфиденциальности перед отправкой',
    'sk': 'Pred odoslaním označením políčka vyjadrite súhlas s našimi pravidlami ochrany osobných údajov',
    'sl': 'Prosimo, da pred pošiljanjem potrdite polje, da se strinjate z našo politiko',
    'cs': 'Před odesláním vyjádřete zaškrtnutím políčka souhlas s našimi zásadami ochrany osobních údajů'
  },
  'tooltip-ip-address': {
    'en': 'Please wait while your connection is being checked',
    'el': 'Παρακαλούμε αναμένετε την ολοκλήρωση του ελέγχου της σύνδεσής σας',
    'bg': 'Моля, изчакайте да се извърши проверка на връзката Ви',
    'fr': 'Veuillez patienter pendant la vérification de votre connexion',
    'de': 'Bitte warten Sie, während Ihre Verbindung geprüft wird',
    'de-at': 'Bitte warten Sie, während Ihre Verbindung geprüft wird',
    'es': 'Esperar mientras se comprueba su conexión',
    'it': 'Attendere che venga verificata la connessione',
    'hr': 'Pričekajte dok se provjerava veza',
    'hu': 'Kérjük, várjon, a kapcsolat ellenőrzése folyamatban van',
    'pl': 'Czekaj, trwa sprawdzanie połączenia',
    'pt': 'Aguarde enquanto a sua ligação é verificada',
    'ro': 'Vă rugăm să așteptați până la testarea conexiunii dvs.',
    'ru': 'Подождите, пока ваше соединение проверяется',
    'sk': 'Počkajte, kým sa skontroluje vaše pripojenie',
    'sl': 'Prosimo, počakajte, da se preveri povezava',
    'cs': 'Vyčkejte prosím, než bude vaše připojení zkontrolováno'
  },
  'tooltip-validation': {
    'en': 'Please type valid responses into all required fields',
    'el': 'Παρακαλούμε εισάγετε έγκυρες απαντήσεις σε όλα τα υποχρεωτικά πεδία',
    'bg': 'Моля, въведете валидни отговори във всички задължителни полета',
    'fr': 'Veuillez saisir des réponses valides dans tous les champs obligatoires',
    'de': 'Bitte in allen Pflichtfeldern gültige Angaben machen',
    'de-at': 'Bitte in allen Pflichtfeldern gültige Angaben machen ',
    'es': 'Escribir respuestas válidas en todos los campos obligatorios',
    'it': 'Inserire risposte valide in tutti i campi obbligatori',
    'hr': 'Unesite važeće odgovore u sva obavezna polja',
    'hu': 'Kérjük, az összes kötelező mezőbe érvényes választ adjon meg',
    'pl': 'Wpisz prawidłowe odpowiedzi we wszystkich wymaganych polach',
    'pt': 'Introduza respostas válidas nos campos obrigatórios',
    'ro': 'Vă rugăm să introduceți răspunsuri valide în toate câmpurile obligatorii',
    'ru': 'Введите верные ответы во все необходимые поля',
    'sk': 'Zadajte platné odpovede do všetkých povinných polí',
    'sl': 'Prosimo, vnesite veljavne odgovore v vsa zahtevana polja',
    'cs': 'Zadejte prosím platné odpovědi do všech povinných polí'
  },
  'tooltip-validation-incl-phone': {
    'en': 'Please type valid responses into all required fields and a valid phone number',
    'el': 'Παρακαλούμε εισάγετε έγκυρες απαντήσεις σε όλα τα υπορεωτικά πεδία και, προαιρετικά, έγκυρο αριθμό τηλεφώνου',
    'bg': 'Моля, въведете валидни отговори във всички задължителни полета и валиден телефонен номер',
    'fr': 'Veuillez saisir des réponses valides dans tous les champs obligatoires et un numéro de téléphone valide',
    'de': 'Bitte in allen Pflichtfeldern gültige Angaben machen und eine gültige Telefonnummer angeben',
    'de-at': 'Bitte in allen Pflichtfeldern gültige Angaben machen und eine gültige Telefonnummer angeben',
    'es': 'Escribir respuestas válidas en todos los campos obligatorios y un número de teléfono válido',
    'it': 'Inserire risposte valide in tutti i campi obbligatori e un numero di telefono valido',
    'hr': 'Unesite važeće odgovore u sva obavezna polja i važeći telefonski broj',
    'hu': 'Kérjük, az összes kötelező mezőbe érvényes választ, valamint egy érvényes telefonszámot adjon meg',
    'pl': 'Wpisz prawidłowe odpowiedzi we wszystkich wymaganych polach i podaj prawidłowy numer telefonu',
    'pt': 'Introduza respostas válidas em todos os campos obrigatórios e um número de telefone válido',
    'ro': 'Vă rugăm să introduceți răspunsuri valide în toate câmpurile obligatorii și un număr de telefon valid',
    'ru': 'Введите верные ответы во все необходимые поля и введите рабочий номер телефона',
    'sk': 'Zadajte platné odpovede do všetkých povinných polí a platné telefónne číslo',
    'sl': 'Prosimo, vnesite veljavne odgovore in veljavno telefonsko številko v vsa veljavna polja',
    'cs': 'Zadejte prosím platné odpovědi do všech povinných polí a uveďte platné telefonní číslo'
  },
  'tooltip-validation-message': {
    'en': `Please type a minimum of ${MESSAGE_MIN_LENGTH} characters in your message`,
    'el': `Παρακαλούμε εισάγετε τουλάχιστον ${MESSAGE_MIN_LENGTH} χαρακτήρες στο μύνημά σας`,
    'bg': `Моля, въведете съобщение с дължина най-малко ${MESSAGE_MIN_LENGTH} знак`,
    'fr': `Veuillez taper un minimum de ${MESSAGE_MIN_LENGTH} caractères dans votre message`,
    'de': `Mindestlänge Ihrer Nachricht ${MESSAGE_MIN_LENGTH} Buchstaben`,
    'de-at': `Mindestlänge Ihrer Nachricht ${MESSAGE_MIN_LENGTH} Buchstaben`,
    'es': `Escribir un mínimo de ${MESSAGE_MIN_LENGTH} caracteres en su mensaje`,
    'it': `Digitare minimo ${MESSAGE_MIN_LENGTH} caratteri nel messaggio`,
    'hr': `Upišite poruku od najmanje ${MESSAGE_MIN_LENGTH} znakova`,
    'hu': `Kérjük, az üzenete legalább ${MESSAGE_MIN_LENGTH} karaktert tartalmazzon`,
    'pl': `Wpisz wiadomość zawierającą co najmniej ${MESSAGE_MIN_LENGTH} znaków`,
    'pt': `A sua mensagem tem de ter, no mínimo, ${MESSAGE_MIN_LENGTH} caracteres`,
    'ro': `Vă rugăm să tastați cel puțin ${MESSAGE_MIN_LENGTH} de caractere în mesajul dvs`,
    'ru': `Введите сообщение длиной минимум ${MESSAGE_MIN_LENGTH} символов`,
    'sk': `Do poľa správy zadajte minimálne ${MESSAGE_MIN_LENGTH} znakov`,
    'sl': `Prosimo, da v svoje sporočilo vnesete najmanj ${MESSAGE_MIN_LENGTH} znakov`,
    'cs': `Prosím, napište do zprávy minimálně ${MESSAGE_MIN_LENGTH} znaků`
  },
  'form-submitted': {
    'en': 'Form submitted successsfully',
    'el': 'Το αίτημά σας καταχωρήθηκε επιτυχώς',
    'bg': 'Формулярът бе изпратен успешно',
    'fr': 'Formulaire soumis avec succès',
    'de': 'Formular erfolgreich übertragen',
    'de-at': 'Formular erfolgreich übertragen',
    'es': 'Formulario enviado correctamente',
    'it': 'Modulo inviato con successo',
    'hr': 'Obrazac je uspješno poslan',
    'hu': 'Az űrlap elküldése sikerült',
    'pl': 'Formularz został przesłany pomyślnie',
    'pt': 'Formulário enviado com êxito',
    'ro': 'Formularul a fost trimis cu succes',
    'ru': 'Форма успешно отправлена',
    'sk': 'Formulár bol úspešne odoslaný',
    'sl': 'Obrazec je bil uspešno oddan',
    'cs': 'Formulář byl úspěšně odeslán'
  },
  'form-failed': {
    'en': 'Form submission failed, this has been reported. Please try again later!',
    'el': 'Η καταχώρηση του αιτήματός σας απέτυχε, έχει γίνει αναφορά. Παρακαλούμε προσπαθήστε αργότερα!',
    'bg': 'Изпращането на формуляра не бе успешно, подаден е сигнал. Моля, опитайте отново по-късно!',
    'fr': 'La soumission du formulaire a échoué, cela a été signalé. Veuillez réessayer plus tard!',
    'de': 'Formularübermittlung fehlgeschlagen, wir wurden  informiert. Bitte versuchen Sie es später noch einmal!',
    'de-at': 'Formularübermittlung fehlgeschlagen, wir wurden  informiert. Bitte versuchen Sie es später noch einmal!',
    'es': 'El envío del formulario ha fallado y se ha notificado. Inténtelo de nuevo más tarde.',
    'it': 'Invio del modulo non riuscito: segnalazione effettuata. Riprovare più tardi!',
    'hr': 'Slanje obrasca nije uspjelo, pogreška je prijavljena. Pokušajte ponovno kasnije!',
    'hu': 'Az űrlap elküldése sikertelen, erről a rendszer jelentést küldött. Kérjük, próbálja meg később!',
    'pl': 'Nie udało się przesłać formularza, błąd został zgłoszony. Spróbuj ponownie później!',
    'pt': 'Falha ao enviar formulário. Problema reportado. Tente novamente mais tarde.',
    'ro': 'Trimiterea formularului a eșuat și acest lucru a fost raportat. Vă rugăm să încercați din nou mai târziu!',
    'ru': 'Не удалось отправить форму; отправлен отчет о неудачной попытке. Повторите попытку позже!',
    'sk': 'Odoslanie formulára zlyhalo, tento problém bol nahlásený. Skúste to neskôr.',
    'sl': 'Oddaja obrazca ni uspela, o tem je bilo poročano. Prosimo, poskusite kasneje!',
    'cs': 'Odeslání formuláře se nezdařilo. Bylo to nahlášeno. Zkuste to prosím později!'
  },
  'thank-you': {
    'en': 'Thank you for your message!',
    'el': 'Ευχαριστούμε για το μήνυμά σας!',
    'bg': 'Благодарим Ви за съобщението!',
    'fr': 'Merci pour votre message!',
    'de': 'Danke für Ihre Nachricht!',
    'de-at': 'Danke für Ihre Nachricht!',
    'es': '¡Gracias por su mensaje!',
    'it': 'Grazie per il messaggio!',
    'hr': 'Hvala na poruci!',
    'hu': 'Köszönjük üzenetét!',
    'pl': 'Dziękujemy za wiadomość!',
    'pt': 'Agradecemos a sua mensagem!',
    'ro': 'Vă mulțumim pentru mesajul dvs.!',
    'ru': 'Спасибо за сообщение!',
    'sk': 'Ďakujeme za vašu správu.',
    'sl': 'Hvala za vaše sporočilo!',
    'cs': 'Děkujeme vám za zprávu!'
  },
  'review': {
    'en': 'We will review your enquiry and get back to you as soon as possible.',
    'el': 'Θα εξετάσουμε το αίτημά σας και θα επανέλθουμε το συντομότερο δυνατό',
    'bg': 'Ще разгледаме запитването Ви и ще се свържем с Вас възможно най-скоро.',
    'fr': 'Nous examinerons votre demande et vous répondrons dans les plus brefs délais.',
    'de': 'Wir werden Ihre Anfrage bearbeiten und uns so schnell wie möglich bei Ihnen melden.',
    'de-at': 'Wir werden Ihre Anfrage bearbeiten und uns so schnell wie möglich bei Ihnen melden.',
    'es': 'Revisaremos su consulta y nos pondremos en contacto con usted lo antes posible.',
    'it': 'Esamineremo la vostra richiesta e vi risponderemo il prima possibile.',
    'hr': 'Pregledat ćemo vaš upit i javit ćemo vam se u najkraćem mogućem roku.',
    'hu': 'Kérését feldolgozzuk, és a lehető leghamarabb kapcsolatba lépünk Önnel.',
    'pl': 'Przeanalizujemy Twoje zapytanie i odpowiemy najszybciej, jak to możliwe.',
    'pt': 'Iremos analisar a sua consulta e entraremos em contacto assim que possível.',
    'ro': 'Vom examina solicitarea dvs. și vă vom răspunde cât mai curând posibil.',
    'ru': 'Мы рассмотрим ваш запрос и свяжемся с вами в кратчайшие сроки.',
    'sk': 'Váš dopyt spracujeme a čo najskôr sa vám ozveme.',
    'sl': 'Pregledali bomo vaše povpraševanje in se vam oglasili v najkrajšem možnem času.',
    'cs': 'Váš dotaz prověříme a co nejdříve se vám ozveme.'
  },
  'contact': {
    'en': 'Contact us',
    'el': 'Επικοινωνία',
    'bg': 'Връзка с нас',
    'fr': 'Nous contacter',
    'de': 'Kontakt',
    'de-at': 'Kontakt',
    'es': 'Contacto',
    'it': 'Contatti',
    'hr': 'Kontaktirajte s nama',
    'hu': 'Kapcsolatfelvétel',
    'pl': 'Kontakt',
    'pt': 'Entre em contacto',
    'ro': 'Contactați-ne',
    'ru': 'Свяжитесь с нами',
    'sk': 'Kontaktujte nás',
    'sl': 'Stopite v stik z nami',
    'cs': 'Kontaktujte nás'
  },
  'website': {
    'en': 'Website',
    'el': 'Ιστοσελίδα',
    'bg': 'Уебсайт',
    'fr': 'Site web',
    'de': 'Website',
    'de-at': 'Website',
    'es': 'Página web',
    'it': 'Sito web',
    'hr': 'Web stranica',
    'hu': 'Weboldal',
    'pl': 'Strona internetowa',
    'pt': 'Sítio Web',
    'ro': 'Site web',
    'ru': 'Сайт',
    'sk': 'Webová lokalita',
    'sl': 'Spletna stran',
    'cs': 'Webové stránky'
  },
  'website-placeholder': {
    'en': 'Type your company website here',
    'el': 'Πληκτρολογήστε τη διεύθυνση της ιστοσελίδας της εταιρείας σας εδώ',
    'bg': 'Въведете тук уебсайта на вашата компания',
    'fr': 'Tapez ici le site web de votre entreprise',
    'de': 'Geben Sie hier Ihre Unternehmenswebsite ein',
    'de-at': 'Geben Sie hier Ihre Unternehmenswebsite ein',
    'es': 'Escriba aquí la página web de su empresa',
    'it': 'Inserisci qui il sito web della tua azienda',
    'hr': 'Ovdje upišite web stranicu svoje tvrtke',
    'hu': 'Írja be ide a cég honlapját',
    'pl': 'Wpisz tutaj stronę internetową swojej firmy',
    'pt': 'Escreva aqui o sítio Web da sua empresa',
    'ro': 'Scrieți aici site-ul companiei dvs',
    'ru': 'Введите здесь веб-сайт вашей компании',
    'sk': 'Sem zadajte webovú stránku svojej spoločnosti',
    'sl': 'Tukaj vnesite spletno stran svojega podjetja',
    'cs': 'Zde zadejte webové stránky své společnosti'
  },
  'send-parcel': {
    'en': 'Send a parcel or pallet',
    'el': 'Αποστολή δέματος ή παλέτας',
    'bg': 'Изпращане на пратка или палет',
    'fr': 'Envoyer un colis ou une palette',
    'de': 'Ein Paket oder eine Palette versenden',
    'de-at': 'Ein Paket oder eine Palette versenden',
    'es': 'Enviar un paquete o palé',
    'it': 'Inviare un pacco o un pallet',
    'hr': 'Pošaljite paket ili paletu',
    'hu': 'Küldjön csomagot vagy raklapot',
    'pl': 'Wyślij paczkę lub paletę',
    'pt': 'Enviar uma encomenda ou palete',
    'ro': 'Trimiteți un colet sau un palet',
    'ru': 'Отправить посылку или паллету',
    'sk': 'Poslať balík alebo paletu',
    'sl': 'Pošljite paket ali paleto',
    'cs': 'Odeslání balíku nebo palety'
  },
  'pick-up-address': {
    'en': 'Pick up address',
    'el': 'Διεύθυνση παραλαβής',
    'bg': 'Адрес за получаване',
    'fr': "Adresse d'enlèvement",
    'de': 'Abholadresse',
    'de-at': 'Abholadresse',
    'es': 'Dirección de recogida',
    'it': 'Indirizzo di ritiro',
    'hr': 'Adresa preuzimanja',
    'hu': 'Átvételi cím',
    'pl': 'Adres odbioru',
    'pt': 'Endereço de recolha',
    'ro': 'Adresa de preluare',
    'ru': 'Адрес самовывоза',
    'sk': 'Adresa vyzdvihnutia',
    'sl': 'Naslov za prevzem',
    'cs': 'Adresa pro vyzvednutí'
  },
  'pick-up-address-placeholder': {
    'en': 'Enter the full pick up address',
    'el': 'Εισάγετε την πλήρη διεύθυνση παραλαβής',
    'bg': 'Въведете пълния адрес за получаване',
    'fr': "Entrez l'adresse complète d'enlèvement",
    'de': 'Geben Sie die vollständige Abholadresse ein',
    'de-at': 'Geben Sie die vollständige Abholadresse ein',
    'es': "Introduzca la dirección de recogida completa",
    'it': "Inserire l'indirizzo completo per il ritiro",
    'hr': 'Unesite punu adresu preuzimanja',
    'hu': 'Adja meg a teljes átvételi címet',
    'pl': 'Wprowadź pełny adres odbioru',
    'pt': 'Introduza o endereço completo de recolha',
    'ro': 'Introduceți adresa completă de preluare',
    'ru': 'Введите полный адрес самовывоза',
    'sk': 'Zadajte úplnú adresu vyzdvihnutia',
    'sl': 'Vpišite celoten naslov za prevzem',
    'cs': 'Zadejte celou adresu pro vyzvednutí'
  },
  'delivery-address': {
    'en': 'Delivery address',
    'el': 'Διεύθυνση παράδοσης',
    'bg': 'Адрес за доставка',
    'fr': 'Adresse de livraison',
    'de': 'Lieferadresse',
    'de-at': 'Lieferadresse',
    'es': 'Dirección de entrega',
    'it': 'Indirizzo di consegna',
    'hr': 'Adresa dostave',
    'hu': 'Kézbesítési cím',
    'pl': 'Adres dostawy',
    'pt': 'Endereço de entrega',
    'ro': 'Adresa de livrare',
    'ru': 'Адрес доставки',
    'sk': 'Adresa doručenia',
    'sl': 'Naslov za dostavo',
    'cs': 'Adresa pro doručení'
  },
  'delivery-address-placeholder': {
    'en': 'Enter the full delivery address',
    'el': 'Εισάγετε την πλήρη διεύθυνση παράδοσης',
    'bg': 'Въведете пълния адрес за доставка',
    'fr': "Entrez l'adresse de livraison complète",
    'de': 'Geben Sie die vollständige Lieferadresse ein',
    'de-at': 'Geben Sie die vollständige Lieferadresse ein',
    'es': 'Introduzca la dirección de entrega completa',
    'it': "Inserire l'indirizzo completo di consegna",
    'hr': 'Unesite punu adresu za dostavu',
    'hu': 'Adja meg a teljes szállítási címet',
    'pl': 'Wpisz pełny adres dostawy',
    'pt': 'Introduza o endereço de entrega completo',
    'ro': 'Introduceți adresa completă de livrare',
    'ru': 'Введите полный адрес доставки',
    'sk': 'Zadajte celú adresu doručenia',
    'sl': 'Vpišite celoten naslov za dostavo',
    'cs': 'Zadejte celou doručovací adresu'
  },
  'shipment-type':{
    'en': 'Type of Shipment',
    'el': 'Τύπος αποστολής',
    'bg': 'Вид на пратката',
    'fr': "Type d'envoi",
    'de': 'Art der Sendung',
    'de-at': 'Art der Sendung',
    'es': 'Tipo de envío',
    'it': 'Tipo di spedizione',
    'hr': 'Vrsta pošiljke',
    'hu': 'A küldemény típusa',
    'pl': 'Rodzaj przesyłki',
    'pt': 'Tipo de envio',
    'ro': 'Tipul de expediere',
    'ru': 'Тип груза',
    'sk': 'Typ zásielky',
    'sl': 'Vrsta pošiljke',
    'cs': 'Typ zásilky'
  },
  'shipment-type-placeholder':{
    'en': 'Select the type of shipment',
    'el': 'Επιλέξτε τον τύπο της αποστολής',
    'bg': "Изберете вида на пратката",
    'fr': "Sélectionner le type d'envoi",
    'de': 'Wählen Sie die Art der Sendung',
    'de-at': 'Wählen Sie die Art der Sendung',
    'es': 'Seleccione el tipo de envío',
    'it': 'Selezionare il tipo di spedizione',
    'hr': 'Odaberite vrstu pošiljke',
    'hu': 'Válassza ki a küldemény típusát',
    'pl': 'Wybierz typ przesyłki',
    'pt': 'Selecionar o tipo de envio',
    'ro': 'Selectați tipul de expediere',
    'ru': 'Выберите тип груза',
    'sk': 'Vyberte typ zásielky',
    'sl': 'Izberite vrsto pošiljke',
    'cs': 'Vyberte typ zásilky'
  },
  'weight':{
    'en': 'Weight (kg)',
    'el': 'Βάρος (κιλά)',
    'bg': 'Тегло (кг)',
    'fr': 'Poids (kg)',
    'de': 'Gewicht (kg)',
    'de-at': 'Gewicht (kg)',
    'es': 'Peso (kg)',
    'it': 'Peso (kg)',
    'hr': 'Težina (kg)',
    'hu': 'Súly (kg)',
    'pl': 'Waga (kg)',
    'pt': 'Peso (kg)',
    'ro': 'Greutate (kg)',
    'ru': 'Вес (кг)',
    'sk': 'Hmotnosť (kg)',
    'sl': 'Teža (kg)',
    'cs': 'Hmotnost (kg)'
  },
  'weight-placeholder':{
    'en': 'Enter the weight of shipment in kg',
    'el': 'Εισάγετε το βάρος της αποστολής σε κιλά',
    'bg': 'Въведете теглото на пратката в кг',
    'fr': "Saisir le poids de l'envoi en kg",
    'de': 'Geben Sie das Gewicht der Sendung in kg ein',
    'de-at': 'Geben Sie das Gewicht der Sendung in kg ein',
    'es': 'Introduzca el peso del envío en kg',
    'it': 'Inserire il peso della spedizione in kg',
    'hr': 'Unesite težinu pošiljke u kg',
    'hu': 'Adja meg a küldemény súlyát kg-ban',
    'pl': 'Wprowadź wagę przesyłki w kg',
    'pt': 'Introduza o peso do envio em kg',
    'ro': 'Introduceți greutatea transportului în kg',
    'ru': 'Укажите вес отправления в кг',
    'sk': 'Zadajte hmotnosť zásielky v kg',
    'sl': 'Vpišite težo pošiljke v kg',
    'cs': 'Zadejte hmotnost zásilky v kg'
  }, 
  'Envelope':{
    'en': 'Envelope',
    'el': 'Φάκελος',
    'bg': 'Плик',
    'fr': 'Enveloppe',
    'de': 'Umschlag',
    'de-at': 'Umschlag',
    'es': 'Sobre',
    'it': 'Busta',
    'hr': 'Omotnica',
    'hu': 'Boríték',
    'pl': 'Koperta',
    'pt': 'Envelope',
    'ro': 'Plic',
    'ru': 'Конверт',
    'sk': 'Obálka',
    'sl': 'Ovojnica',
    'cs': 'Obálka'
  }, 
  'Package':{
    'en': 'Package',
    'el': 'Πακέτο',
    'bg': 'Пакет',
    'fr': 'Colis',
    'de': 'Paket',
    'de-at': 'Paket',
    'es': 'Paquete',
    'it': 'Pacco',
    'hr': 'Paket',
    'hu': 'Csomag',
    'pl': 'Paczka',
    'pt': 'Embalagem',
    'ro': 'Pachet',
    'ru': 'Пакет',
    'sk': 'Balík',
    'sl': 'Paket',
    'cs': 'Balík'
  }, 
  'Pallet':{
    'en': 'Pallet',
    'el': 'Παλέτα',
    'bg': 'Палет',
    'fr': 'Palette',
    'de': 'Palette',
    'de-at': 'Palette',
    'es': 'Palé',
    'it': 'Pallet',
    'hr': 'Paleta',
    'hu': 'Raklap',
    'pl': 'Paleta',
    'pt': 'Palete',
    'ro': 'Palet',
    'ru': 'Паллета',
    'sk': 'Paleta',
    'sl': 'Paleta',
    'cs': 'Paleta'
  }, 
  'featured':{
    'en': 'featured',
    'el': 'προτεινόμενα',
    'bg': 'препоръчани',
    'fr': 'en-vedette',
    'de': 'ausgewahlt',
    'de-at': 'ausgewahlt',
    'es': 'destacados',
    'it': 'in-evidenza',
    'hr': 'featured',
    'hu': 'kiemelt',
    'pl': 'polecane',
    'pt': 'featured',
    'ro': 'prezentate',
    'ru': 'изображать',
    'sk': 'featured',
    'sl': 'featured',
    'cs': 'featured'
  },
  'jobs':{
    'en': 'Pursue a lasting career at BIZ Courier & Logistics!',
    'el': 'Σταδιοδρομήστε στην BIZ Courier & Logistics!',
    'bg': 'Преследвайте трайната си кариера в BIZ Courier & Logistics!',
    'fr': 'Poursuivez une carrière durable chez BIZ Courier & Logistics !',
    'de': 'Machen Sie eine nachhaltige Karriere bei BIZ Courier & Logistics!',
    'de-at': 'Machen Sie eine nachhaltige Karriere bei BIZ Courier & Logistics!',
    'es': '¡Persigue una carrera duradera en BIZ Courier & Logistics!',
    'it': 'Una carriera duratura in BIZ Courier & Logistics!',
    'hr': 'Ostvarite trajnu karijeru u BIZ Courier & Logistics!',
    'hu': 'Folytasson tartós karriert a BIZ Courier & Logistics-nál!',
    'pl': 'Trwała kariera w BIZ Courier & Logistics!',
    'pt': 'Faça uma carreira duradoura na BIZ Courier & Logistics!',
    'ro': 'Urmează o carieră de durată la BIZ Courier & Logistics!',
    'ru': 'Сделайте долгосрочную карьеру в компании BIZ Courier & Logistics!',
    'sk': 'Vytvorte si trvalú kariéru v spoločnosti BIZ Courier & Logistics!',
    'sl': 'Pri BIZ Courier & Logistics si ustvarite trajno kariero!',
    'cs': 'Udělejte si trvalou kariéru ve společnosti BIZ Courier & Logistics!'
  }, 
  'jobs-button':{
    'en': 'ALL JOBS',
    'el': 'ΟΛΕΣ ΟΙ ΘΕΣΕΙΣ ΕΡΓΑΣΙΑΣ',
    'bg': 'ВСИЧКИ РАБОТНИ МЕСТА',
    'fr': 'TOUS LES EMPLOIS',
    'de': 'ALLE JOBS',
    'de-at': 'ALLE JOBS',
    'es': 'TODOS LOS EMPLEOS',
    'it': 'TUTTI I LAVORI',
    'hr': 'SVI POSLOVI',
    'hu': 'MINDEN ÁLLÁS',
    'pl': 'WSZYSTKIE OFERTY PRACY',
    'pt': 'TODOS OS EMPREGOS',
    'ro': 'TOATE LOCURILE DE MUNCĂ',
    'ru': 'ВСЕ РАБОТЫ',
    'sk': 'VŠETKY PRACOVNÉ POZÍCIE',
    'sl': 'VSA DELOVNA MESTA',
    'cs': 'VŠECHNA PRACOVNÍ MÍSTA'
  },
  'delivery-country':{
    'en': 'Delivery country',
    'el': 'Χώρα παράδοσης',
    'bg': 'Държава на доставка',
    'fr': 'Pays de livraison',
    'de': 'Land der Lieferung',
    'de-at': 'Land der Lieferung',
    'es': 'País de entrega',
    'it': 'Paese di consegna',
    'hr': 'Zemlja isporuke',
    'hu': 'Szállító ország',
    'pl': 'Kraj dostawy',
    'pt': 'País de entrega',
    'ro': 'Țara de livrare',
    'ru': 'Страна доставки',
    'sk': 'Krajina dodania',
    'sl': 'Država dostave',
    'cs': 'Země dodání'
  },
  'delivery-country-placeholder':{
    'en': 'Select the delivery country',
    'el': 'Επιλέξτε τη χώρα παράδοσης',
    'bg': 'Изберете страната на доставка',
    'fr': 'Sélectionner le pays de livraison',
    'de': 'Wählen Sie das Lieferland',
    'de-at': 'Wählen Sie das Lieferland',
    'es': 'Seleccione el país de entrega',
    'it': 'Selezionare il paese di consegna',
    'hr': 'Odaberite zemlju isporuke',
    'hu': 'Válassza ki a szállítási országot',
    'pl': 'Wybierz kraj dostawy',
    'pt': 'Selecionar o país de entrega',
    'ro': 'Selectați țara de livrare',
    'ru': 'Выберите страну доставки',
    'sk': 'Vyberte krajinu dodania',
    'sl': 'Izberite državo dostave',
    'cs': 'Vyberte si zemi doručení'
  },
  'pick-up-country':{
    'en': 'Pick up country',
    'el': 'Χώρα παραλαβής',
    'bg': 'Държава на получаване',
    'fr': "Pays d'enlèvement",
    'de': 'Land der Abholung',
    'de-at': 'Land der Abholung',
    'es': 'País de recogida',
    'it': 'Paese di ritiro',
    'hr': 'Pokupi državu',
    'hu': 'Átvételi ország',
    'pl': 'Kraj odbioru',
    'pt': 'País de recolha',
    'ro': 'Țara de preluare',
    'ru': 'Страна самовывоза',
    'sk': 'Krajina vyzdvihnutia',
    'sl': 'Država prevzema',
    'cs': 'Země vyzvednutí'
  },
  'pick-up-country-placeholder':{
    'en': 'Select the pick up country',
    'el': 'Επιλέξτε τη χώρα παραλαβής',
    'bg': 'Изберете страната на взимане',
    'fr': "Sélectionner le pays d'enlèvement",
    'de': 'Wählen Sie das Abholland',
    'de-at': 'Wählen Sie das Abholland',
    'es': 'Seleccione el país de recogida',
    'it': 'Selezionare il paese di ritiro',
    'hr': 'Odaberite državu preuzimanja',
    'hu': 'Válassza ki a felvételi országot',
    'pl': 'Wybrać kraj odbioru',
    'pt': 'Selecionar o país de recolha',
    'ro': 'Selectați țara de preluare',
    'ru': 'Выберите страну получения',
    'sk': 'Vyberte krajinu vyzdvihnutia',
    'sl': 'Izberite državo zbiranja',
    'cs': 'Výběr země vyzvednutí'
  }
};

