<div
  class="row row-header">
  <span>
    <span class="contact-label">{{
    getLabelLanguage(
      'contact'
    )
  }}</span><br/><span class="company">BIZ Courier & Logistics S.A.</span></span>
</div>

<div
  class="row row-contact-details"
  [lang]="languageCode">
  <a
    class="subrow"
    href="tel:+302117101466">
    <img
      src="assets/vectors/phone-outline.svg"/>
    <span>+30 211 71 01 466</span>
  </a>
  <a
    class="subrow"
    href="mailto:info@bizcourier.eu">
    <img
      src="assets/vectors/email-outline.svg"/>
    <span>info@bizcourier.eu</span>
  </a>
</div>

<div
  class="row"
  [lang]="languageCode">
  <span>{{
    getLabelLanguage(
      'offices'
    )
  }}</span>
  <span>{{
    getLabelLanguage(
      'address'
    )
  }}</span>
  <span>19400, {{
    getLabelLanguage(
      'athens'
    )
  }}, {{
    getLabelLanguage(
      'greece'
    )
  }}</span>
</div>

<div
  class="row"
  [lang]="languageCode">
  <span>{{
    getLabelLanguage(
      'working-hours'
    )
  }}</span>
  <span>{{
    getLabelLanguage(
      'monday'
    )
  }} - {{
    getLabelLanguage(
      'friday'
    )
  }}</span>
  <span>09:00 - 17:00 EET / EEST</span>
</div>