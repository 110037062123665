import * as countriesIso
  from 'i18n-iso-countries';

import * as countryLanguage
  from 'country-language';

import * as countryQuery
  from 'countries-code';

import * as naturalSortByKey
  from 'natural-sort-by-key';

import {
  titleCase
} from 'title-case';

import {
  validate
} from 'email-validator';

import {
  COUNTRY_CODES_SUPPORTED,
  COUNTRY_ZONE_MAP,
  LANGUAGE_CODE_DEFAULT,
  LANGUAGE_CODES_SUPPORTED,
  PREFERRED_COUNTRY_CODES
} from './app.const';

const countryCodes = (
  countryLanguage
    .getCountries()
    .map(
      country => (
        country
          .code_2
          .toLowerCase()
      )
    )
);

const languageCodes = (
  countryLanguage
    .getLanguageCodes()
    .map(
      (
        languageCode: string
      ) => (
        languageCode
          .toLowerCase()
      )
    )
);

export const formatPhone = (
  phone: string
) => {
  let phoneClean = (
    phone
      .replace(
        /[^0-9+]/g,
        ''
      )
  );

  
  if (
    phoneClean
      .includes(
        '+'
      )
  ) {
    const phoneSplit = (
      phoneClean
        .split(
          ''
        )
    );
    
    phoneClean = (
      [
        phoneSplit[0],
        ...(
          phoneSplit
            .slice(
              1
            )
            .filter(
              char => (
                char !==
                '+'
              )
            )
        )
      ].join(
        ''
      )
    );
  }

  return (
    (
      phoneClean
        .startsWith(
          '+'
        )
    ) ?
      (
        phoneClean
        .slice(
          0,
          16
        )
      ) :
      (
        phoneClean
          .slice(
            0,
            15
          )
      )
  );
};

export const getCountryName = (
  code: string
): string => (
  (
    code ===
    'cz'
  ) ?
    'Czech Republic' :
    (
      countryQuery
        .getCountry(
          code
        )
    )
);

export const getCountryNameLanguage = (
  countryCode: string,
  languageCode: string
) => (
  countriesIso
    .getName(
      countryCode,
      (
        (
          languageCode
            .startsWith(
              'de-'
            )
        ) ?
          'de' :
          languageCode
      )
    )
);

export const getCountryOptions = (
  languageCode: string = 'en'
) => ({
  preferred: (
    countryCodes
      .filter(
        code => (
          PREFERRED_COUNTRY_CODES
            .includes(
              code
            )
        )
      )
      .map(
        code => ({
          code,
          name: (
            getCountryNameLanguage(
              code,
              languageCode
            )
          )
        })
      )
      .sort(
        naturalSortByKey(
          'name'
        )
      )
  ),
  other: (
    countryCodes
      .filter(
        code => (
          !(
            PREFERRED_COUNTRY_CODES
              .includes(
                code
              )
          )
        )
      )
      .map(
        code => ({
          code,
          name: (
            getCountryNameLanguage(
              code,
              languageCode
            )
          )
        })
      )   
      .sort(
        naturalSortByKey(
          'name'
        )
      )
  )
});

export const getLabelLanguage = (
  key: string,
  languageCode: string,
  languageMap: object,
  brandName?: string,
  fallback: string = ''
) => {
  if (
    !(
      isStringSafe(
        languageCode
      )
    )
  ) {
    return fallback;
  }

  if (
    isCountryZoneCodeSafe(
      key
    )
  ) {
    return (
      COUNTRY_ZONE_MAP[
        key
      ][
        languageCode
      ]
    );
  }

  if (
    isCountryCodeSafe(
      key
    )
  ) {
    return (
      getCountryNameLanguage(
        key,
        languageCode
      )
    );
  }

  if (
    !(
      languageMap[
        key
      ]
    )
  ) {
    return fallback;
  }

  if (
    (
      (
        isStringSafeNotEmpty(
          brandName
        )
      ) &&
      (
        isStringSafe(
          languageMap[
            key
          ][
            languageCode
          ]
        )
      )
    )
  ) {
    return (
      (
        languageMap[
          key
        ][
          languageCode
        ]
      )
      .replace(
        (
          new RegExp(
            brandName,
            'gi'
          )
        ),
        (
          toTitleCase(
            brandName
          )
        )
      )
    );
  }

  return (
    languageMap[
      key
    ][
      languageCode
    ]
  );
}

export const getModelOption = (
  options,
  code
) => {
  if (
    !(
      (
        typeof options ===
        'object'
      ) &&
      (
        Array
          .isArray(
            options
              .preferred
          )
      ) &&
      (
        Array
          .isArray(
            options
              .other
          )
      )
    )
  ) {
    return null;
  }

  return (
    [
      ...(
        options
          .preferred
      ),
      ...(
        options
          .other
      )
    ].filter(
      option => (
        (
          option
            .code
        ) ===
        code
      )
    )[0]
  );
};

export const getLanguageCodesSupported = () => (
  LANGUAGE_CODES_SUPPORTED
);

export const getOptionsDeliveryCountry = () => ([
  ...(
    Object
      .keys(
        COUNTRY_ZONE_MAP
      )
      .map(
        zoneCode => ({
          label: (
            COUNTRY_ZONE_MAP[
              zoneCode
            ][
              LANGUAGE_CODE_DEFAULT
            ]
          ),
          value: zoneCode
        })
      )
  ),
  ...(
    COUNTRY_CODES_SUPPORTED
      .map(
        countryCode => ({
          label: (
            getCountryName(
              countryCode
            )
          ),
          value: countryCode
        })
      )
      .sort(
        (
          countryA,
          countryB
        ) => (
          countryA
            .label
            .localeCompare(
              countryB
                .label
            )
        )
      )
  )
]);

export const isCountryCodeSafe = value => (
  isStringSafe(
    value
  ) &&
  (
    countryCodes
      .includes(
        value
          .toLowerCase()
      )
  )
);

export const isCountryZoneCodeSafe = value => (
  isStringSafe(
    value
  ) &&
  (
    Object
      .keys(
        COUNTRY_ZONE_MAP
      )
      .includes(
        value
      )
  )
);

export const isEmailSafe = value => (
  (
    isStringSafe(
      value
    )
  ) &&
  (
    validate(
      value
    )
  )
);

export const isFuncSafe = value => (
  (
    value !==
    null
  ) &&
  (
    typeof value ===
    'function'
  )
);

export const isLanguageCodeSafe = value => (
  isStringSafe(
    value
  ) &&
  (
    languageCodes
      .includes(
        value
          .toLowerCase()
      )
  )
);

export const isNumberSafe = value => (
  (
    value !==
    null
  ) &&
  (
    typeof value ===
    'number'
  ) &&
  !(
    isNaN(
      value
    )
  )
);


export const isPhoneSafe = (
  phone: string
) => (
  (
    isStringSafe(
      phone
    )
  ) &&
  (
    (
      (
        phone
          .startsWith(
            '+'
          )
      ) &&
      (
        (
          phone
            .length
        ) >=
        8
      ) &&
      (
        (
          phone
            .length
        ) <=
        16
      )
    ) ||
    (
      (
        !(
          phone
            .startsWith(
              '+'
            )
        )
      ) &&
      (
        (
          phone
            .length
        ) >=
        7
      ) &&
      (
        (
          phone
            .length
        ) <=
        15
      )
    )
  )
);

export const isStringSafe = value => (
  (
    value !==
    null
  ) &&
  (
    typeof value ===
    'string'
  )
);

export const isStringSafeNotEmpty = value => (
  (
    isStringSafe(
      value
    )
  ) &&
  (
    (
      value
        .length
    ) >
    0
  )
);

export const registerLocale = (
  languageCode: string
) => (
  countriesIso
    .registerLocale(
      require(
        `i18n-iso-countries/langs/${(
          languageCode
        )}.json`
      )
    )
);

export const toTitleCase = value => (
  (
    isStringSafeNotEmpty(
      value
    )
  ) ?
    (
      (
        titleCase(
          value
            .toLowerCase()
            .replace(
              /_/g,
              ' '
            )
        )
      )
      .replace(
        /Biz/g,
        'BIZ'
      )
      .replace(
        /Lp/g,
        'LP'
      )
      .replace(
        /Lmp/g,
        'LMP'
      )
      .replace(
        /Dhl/g,
        'DHL'
      )
      .replace(
        /Gls/g,
        'GLS'
      )
      .replace(
        /Ups/g,
        'UPS'
      )
      .replace(
        /Inpost/g,
        'InPost'
      )
      .replace(
        /Abcplus/g,
        'ABC+'
      )
      .split(
        ' '
      )
      .map(
        stem => (
          (
            isCountryCodeSafe(
              stem
            )
          ) ?
            (
              stem
                .toUpperCase()
            ) :
            stem
        )
      )
      .join(
        ' '
      )
  ) :
  value
);