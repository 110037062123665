

import {
  NgModule
} from '@angular/core';

import {
  CommonModule
} from '@angular/common';

import {
  FormsModule
} from '@angular/forms';

import {
  HttpClientModule
} from '@angular/common/http';

import {
  NbButtonModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbInputModule,
  NbSelectModule,
  NbToggleModule
} from '@nebular/theme';

import {
  TooltipModule,
  TooltipOptions
} from '@teamhive/ngx-tooltip';

import {
  FormElementsModule
} from '../form-elements';

import {
  ResponseService
} from '../providers';

import {
  FormContactPageComponent
} from './form-contact';

@NgModule({
  declarations: [
    FormContactPageComponent
  ],
  imports: [
    CommonModule,
    FormElementsModule,
    FormsModule,
    HttpClientModule,
    NbButtonModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbInputModule,
    NbSelectModule,
    NbToggleModule,
    (
      TooltipModule
        .forRoot(
          {
            placement: 'top',
            arrowType: 'sharp',
            allowHTML: false,
            delay: [
              0,
              0
            ],
            duration: [
              400,
              40
            ],
            hideOnClick: false,
            maxWidth: 540,
            showOnInit: true
          } as TooltipOptions
        )
    )
  ],
  exports: [
    FormContactPageComponent
  ],
  providers: [
    ResponseService,
    {
      provide: 'Window',
      useValue: window
    }
  ]
})
export class FormPagesModule {}