import {
  NgModule
} from '@angular/core';

import {
  RouterModule,
  Routes
} from '@angular/router';

import {
  FormContactPageComponent
} from '../form-pages';

const routes: Routes = [
  {
    path: 'contact',
    component: FormContactPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule
      .forRoot(
        routes
      )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
