import {
  NgModule
} from '@angular/core';

import {
  BrowserModule
} from '@angular/platform-browser';

import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';

import {
  NbThemeModule,
  NbLayoutModule
} from '@nebular/theme';

import {
  NbEvaIconsModule
} from '@nebular/eva-icons';

import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';

import {
  FormPagesModule
} from '../form-pages';

import {
  AppRoutingModule
} from './app-routing.module';

import {
  AppComponent
} from './app.component';

import {
  GOOGLE_TAG_MANAGER_ID
} from './app.const';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormPagesModule,
    NbLayoutModule,
    NbEvaIconsModule,
    (
      NgxGoogleAnalyticsModule
        .forRoot(
          GOOGLE_TAG_MANAGER_ID
        )
    ),
    NgxGoogleAnalyticsRouterModule,
    (
      NbThemeModule
        .forRoot({
          name: 'biz'
        })
    )
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
