<div
  class="row row-header">
  <span>
    <span
      class="company"
      [innerHTML]="companyName">
    </span>
  </span>
</div>

<div
  class="row"
  [lang]="languageCode">
  <span
    class="address">{{
    getLabelLanguage(
      addressKey
    )
  }}</span>
  <span
    class="address">{{
    getLabelLanguage(
      postcodeKey
    )
  }}, {{
    getLabelLanguage(
      cityKey
    )
  }}, {{
    getLabelLanguage(
      countryKey
    )
  }}</span>
</div>

<div
  class="row"
  [lang]="languageCode">
  <span
    class="working-hours-label">{{
    getLabelLanguage(
      'working-hours'
    )
  }}</span>
  <span
  class="working-days">{{
    getLabelLanguage(
      'monday'
    )
  }} - {{
    getLabelLanguage(
      'friday'
    )
  }}</span>
  <span
  class="working-hours">{{
    getLabelLanguage(
      workingHoursKey
    )
  }} {{(
    (
      countryKey ===
      'greece'
    ) ?
      'EET / EEST' :
      'CET / CEST'
  )}}</span>
  <div class="flexColumn" *ngIf="weekendWorkingDaysKey && weekendWorkingHoursKey">
    <span
  class="working-days">{{
    getLabelLanguage(
      weekendWorkingDaysKey
    )
  }}</span>
  <span
  class="working-hours">{{
    getLabelLanguage(
      weekendWorkingHoursKey
    )
  }} {{(
    (
      countryKey ===
      'greece'
    ) ?
      'EET / EEST' :
      'CET / CEST'
  )}}</span>
  </div>
</div>