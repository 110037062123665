<div class="form-container">
<div
  class="section header"
  *ngIf="contactBoxShow">
  <div
    class="form-contact-box-container"
    *ngIf="!contactBoxShowEu">
    <div
      class="background">
    </div>
    
    <biz-form-contact-box
      [languageCode]="languageCode">
    </biz-form-contact-box>
  </div>
  
  <div
    class="form-contact-details-container-eu"
    *ngIf="contactBoxShowEu && brandName !== 'abcplus'">
    <h3
      [innerHTML]="(
        getLabelLanguage(
          'contact'
        )
      )"
      *ngIf="!isNew">
    </h3>

    <div
      class="form-contact-boxes-container-eu">
      <div
        class="form-contact-box-container">
        <div
          class="background">
        </div>

        <biz-form-contact-box-eu
          [isNew]="isNew"
          [whiteTheme]="whiteTheme"
          [horizontalLayout]="horizontalLayout"
          [languageCode]="languageCode">
        </biz-form-contact-box-eu>
      </div>

      <div
        class="form-contact-box-container">
        <div
          class="background">
        </div>

        <biz-form-contact-box-eu
          companyName="BIZ Courier & <span class='highlight'>Logistics GmbH</span>"
          [addressKey]="'address-de'"
          [cityKey]="'leipzig'"
          [countryKey]="'germany'"
          [isNew]="isNew"
          [whiteTheme]="whiteTheme"
          [horizontalLayout]="horizontalLayout"
          [languageCode]="languageCode"
          [postcodeKey]="'postcode-de'">
        </biz-form-contact-box-eu>
      </div>
    </div>
  </div>
  <div
  class="form-contact-details-container-eu"
  *ngIf="contactBoxShowEu && brandName === 'abcplus'">
  <h3
    [innerHTML]="(
      getLabelLanguage(
        'contact'
      )
    )"
    *ngIf="!isNew">
  </h3>

  <div
    class="form-contact-boxes-container-eu">
    <div
      class="form-contact-box-container">
      <div
        class="background">
      </div>

      <biz-form-contact-box-eu
      companyName="<span class='highlight'>Asmen</span> Distribucion SL"
        [addressKey]="'address-asmen'"
        [cityKey]="'valencia'"
        [countryKey]="'spain'"
        [workingHoursKey]="'asmen-working-hours'"
        [weekendWorkingDaysKey]="'saturday'"
        [weekendWorkingHoursKey]="'asmen-weekend-working-hours'"
        [isNew]="isNew"
        [whiteTheme]="whiteTheme"
        [horizontalLayout]="horizontalLayout"
        [languageCode]="languageCode"
        [postcodeKey]="'postcode-asmen'">
      </biz-form-contact-box-eu>
    </div>

    <div
      class="form-contact-box-container">
      <div
        class="background">
      </div>

      <biz-form-contact-box-eu
      companyName="<span class='highlight'>BIZ Courier</span> & Logistics S.A."
      [isNew]="isNew"
      [whiteTheme]="whiteTheme"
      [horizontalLayout]="horizontalLayout"
      [languageCode]="languageCode">
      </biz-form-contact-box-eu>
    </div>
  </div>
</div>
</div>

<div class="section jobs"
*ngIf="(!Jobs && isNew)">
  <div class="flexRow">
    <h5>
      {{
        getLabelLanguage(
          'jobs'
        )
      }}
    </h5>
    <a [href]="getCareerLink()" target="_blank">
      <button>
      {{
        getLabelLanguage(
          'jobs-button'
        )
      }}
    </button>
  </a>
  </div>
</div>
</div>
<div class="form-container">
<div
  class="section heading"
  [lang]="languageCode"
  [ngClass]="{
    'has-header': (
      contactBoxShow
    )
  }">
  <ng-container
    *ngIf="!success">
    <div
      class="notice"
      *ngIf="!error">
      {{
        getLabelLanguage(
          'required-fields'
        )
      }} (*)
    </div>

    <div
      class="notice error"
      *ngIf="error">
      {{ error }}
    </div>

    <div
      class="subheading">{{
        getLabelLanguage(
          'personal-contact-info'
        )
      }}</div>
  </ng-container>

  <ng-container
    *ngIf="success">
    <div
      class="notice">{{
        getLabelLanguage(
          'form-submitted'
        )
    }}</div>
  </ng-container>
</div>

<div
  class="section body success"
  *ngIf="success">
  <div
    class="image-container">
    <img
      src="assets/vectors/email-check.svg"
      [ngClass]="{
        'visible': (
          imageLoaded
        )
      }"
      (load)="onImageLoaded()"/>
  </div>

  <div
    class="message"
    [lang]="el">{{
      getLabelLanguage(
        'thank-you'
      )
    }}<br/>{{
      getLabelLanguage(
        'review'
      )
    }}
  </div>
</div>

<div
  class="section body"
  *ngIf="!success">
  <div
    class="col">
    <nb-form-field>
      <label>{{
          getLabelLanguage(
            'name'
          )
        }}&nbsp;(<span
          class="required"
          [ngClass]="{
            'error': (
              sendAttempted &&
              !isValidName
            )
          }">*</span>)
      </label>

      <input
        nbInput
        ngxTooltip
        tooltipGroup="input-name"
        tooltipTrigger="manual"
        [tooltipContent]="tooltipName"
        [placeholder]="(
          getLabelLanguage(
            'name-placeholder'
          )
        )"
        [ngClass]="{
          'success': (
            isValidName
          ),
          'error': (
            sendAttempted &&
            !isValidName
          )
        }"
        [ngModel]="name"
        (ngModelChange)="onValue(
          'name',
          $event
        )"/>
    </nb-form-field>

    <nb-form-field>
      <label>{{
          getLabelLanguage(
            'email'
          )
        }}&nbsp;(<span
          class="required"
          [ngClass]="{
            'error': (
              sendAttempted &&
              !isValidEmail
            )
          }">*</span>)
      </label>

      <input
        nbInput
        ngxTooltip
        type="email"
        tooltipGroup="input-email"
        tooltipTrigger="manual"
        [tooltipContent]="tooltipEmail"
        [placeholder]="(
          getLabelLanguage(
            'email-placeholder'
          )
        )"
        [ngClass]="{
          'success': (
            isValidEmail
          ),
          'error': (
            sendAttempted &&
            !isValidEmail
          )
        }"
        [ngModel]="email"
        (ngModelChange)="onValue(
          'email',
          $event
        )"/>
    </nb-form-field>

    <nb-form-field>
      <label>{{
          getLabelLanguage(
            'phone'
          )
        }}<ng-container
            *ngIf="isRequiredPhone">&nbsp;(<span
            class="required"
            [ngClass]="{
              'error': (
                sendAttempted &&
                !isValidEmail
              )
            }">*</span>)
          </ng-container>
      </label>

      <input
        #inputPhone
        nbInput
        ngxTooltip
        tooltipGroup="input-phone"
        tooltipTrigger="manual"
        [tooltipContent]="tooltipPhone"
        [placeholder]="(
          getLabelLanguage(
            'phone-placeholder'
          )
        )"
        [ngClass]="{
          'success': (
            isValidPhone
          ),
          'error': (
            sendAttempted &&
            !(
              isValidPhone ||
              isValidPhoneEmpty
            )
          )
        }"
        [ngModel]="phone"
        (ngModelChange)="onValue(
          'phone',
          $event
        )"/>
    </nb-form-field>

    <nb-form-field>
      <label>{{
          getLabelLanguage(
            'company-name'
          )
        }}</label>

      <input
        nbInput
        [placeholder]="(
          getLabelLanguage(
            'company-name-placeholder'
          )
        )"
        [ngModel]="companyName"
        (ngModelChange)="onValue(
          'companyName',
          $event
        )"/>
    </nb-form-field>

    <div
    class="row nb-form-field webField"
    *ngIf="(
      hasDelivery ||
      hasFulfillment || 
      hasReturns
    )">
  <nb-form-field>
    <label>{{
        getLabelLanguage(
          'website'
        )
      }}</label>
      <input
      nbInput
      [ngClass]="{
        'validWeb': (
          webStatus === 'valid'
        ),
        'invalidWeb': (
          webStatus === 'invalid' 
        ),
        'possibleValidWeb': (
          webStatus === 'unverified'
        )
      }"
      [placeholder]="getLabelLanguage('website-placeholder')"
      [(ngModel)]="website"
      (blur)="onBlur(website)"/>
  
  <!-- Spinner and Icon -->
  <div class="webIcon">
  <div *ngIf="loading" class="spinner"></div> <!-- Spinner is added here -->
  <div *ngIf="!loading">
      <div *ngIf="webStatus === 'valid'" class="validWebIcon"></div>
      <div *ngIf="webStatus === 'invalid'" class="invalidWebIcon"></div>
      <div *ngIf="webStatus === 'unverified'" class="possibleValidWebIcon"></div>
  </div></div>
  
     
  </nb-form-field>
</div>

    <nb-form-field>
      <label>{{
          getLabelLanguage(
            'position'
          )
        }}
      </label>

      <input
        nbInput
        [placeholder]="(
          getLabelLanguage(
            'position-placeholder'
          )
        )"
        [ngModel]="position"
        (ngModelChange)="onValue(
          'position',
          $event
        )"/>
    </nb-form-field>

    
    <nb-form-field
      *ngIf="countryOptions">
      <label>{{
          getLabelLanguage(
            'country'
          )
        }}
      </label>

      <nb-select
        [placeholder]="(
          getLabelLanguage(
            'country-placeholder'
          )
        )"
        [selected]="(
          countryOptionActiveCode
        )"
        (selectedChange)="(
          setCountryOptionActive(
            $event,
            true
          )
        )">
        <nb-select-label>{{
          countryOptionActiveName
        }}</nb-select-label>

        <nb-option-group
          [title]="(
            getLabelLanguage(
              'country-biz-network'
            )
          )">
          <nb-option
            [value]="country.code"
            *ngFor="
              let country of (
                countryOptions
                  .preferred
              )
            ">
            {{ country.name }}
          </nb-option>
        </nb-option-group>
        
        <nb-option-group
          [title]="(
            getLabelLanguage(
              'country-other'
            )
          )">
          <nb-option
            [value]="country.code"
            *ngFor="
              let country of (
                countryOptions
                  .other
              )
            ">
            {{ country.name }}
          </nb-option>
        </nb-option-group>
      </nb-select>
    </nb-form-field>

    <nb-form-field
      *ngIf="consignmentCodeShow">
      <label>{{
          getLabelLanguage(
            'consignment-code'
          )
        }}</label>

      <input
        nbInput
        [placeholder]="(
          getLabelLanguage(
            'consignment-code-placeholder'
          )
        )"
        [ngModel]="consignmentCode"
        (ngModelChange)="onValue(
          'consignmentCode',
          $event
        )"/>
    </nb-form-field>

    <nb-form-field>
      <label>{{
          getLabelLanguage(
            'message'
          )
        }}&nbsp;(<span
          class="required"
          [ngClass]="{
            'error': (
              sendAttempted &&
              !isValidMessage
            )
          }">*</span>)
      </label>

      <textarea
        #textareaMessage
        nbInput
        [placeholder]="(
          getLabelLanguage(
            'message-placeholder'
          )
        )"
        [ngClass]="{
          'success': (
            isValidMessage
          ),
          'error': (
            sendAttempted &&
            !isValidMessage
          )
        }"
        [ngModel]="message"
        (ngModelChange)="onValue(
          'message',
          $event
        )"></textarea>

      <div
        class="character-count"
        [lang]="languageCode"
        [ngClass]="{
          'done': (
            messageLengthMin ===
            0
          )
        }">{{
          messageLengthLabel
        }}</div>
    </nb-form-field>
  </div>

  <div
    class="col">
    <div
      class="form-field toggle-field">
      <label>{{
        getLabelLanguage(
          'interested-in'
        )
      }}</label>

      <nb-toggle
        nbInput
        labelPosition="end"
        [ngModel]="hasDelivery"
        (ngModelChange)="(
          setHasDelivery(
            $event
          )
        )">{{
          getLabelLanguage(
            'shipping-delivery'
          )
        }}</nb-toggle>
    </div>

    <div
      class="form-field toggle-field toggle-field">
      <nb-toggle
        labelPosition="end"
        [ngModel]="hasFulfillment"
        (ngModelChange)="(
          setHasFulfillment(
            $event
          )
        )">{{
          getLabelLanguage(
            'product-storage-fulfillment'
          )
        }}</nb-toggle>
    </div>
    
    <div
      class="form-field toggle-field toggle-field-last"
      [ngClass]="{
        'disabled': hasDelivery || hasFulfillment
      }"
      *ngIf="isNew"
      >
      <nb-toggle
        labelPosition="end"
        [ngModel]="hasParcel"
        (ngModelChange)="(
          setHasParcel(
            $event
          )
        )">{{
          getLabelLanguage(
            'send-parcel'
          )
        }}</nb-toggle>

    </div>

    <div
      class="form-field toggle-field">
      <label>{{
        getLabelLanguage(
          'specialist-services'
        )
      }}</label>

      <div
        class="form-field toggle-field"
        [ngClass]="{
          'disabled': !hasDelivery || hasParcel
        }">
        <nb-toggle
          labelPosition="end"
          [ngModel]="hasCod"
          (ngModelChange)="onValue(
            'hasCod',
            $event
          )">{{
            getLabelLanguage(
              'cash-on-delivery'
            )
          }}</nb-toggle>
      </div>

      <div
        class="form-field toggle-field toggle-field-last"
        [ngClass]="{
          'disabled': hasParcel
        }">
        <nb-toggle
          labelPosition="end"
          [ngModel]="hasReturns"
          (ngModelChange)="(
            setHasReturns(
              $event
            )
          )">{{
            getLabelLanguage(
              'returns-management'
            )
          }}</nb-toggle>
      </div>
    </div>

    <div
      class="row"
      *ngIf="(
        hasDelivery ||
        hasFulfillment
      )">
      <nb-form-field>
        <label>{{
            getLabelLanguage(
              'delivery-countries'
            )
          }}&nbsp;(<span
            class="required"
            [ngClass]="{
              'error': (
                sendAttempted &&
                !isValidDeliveryCountryCodes
              )
            }">*</span>)
        </label>

        <nb-select
          multiple
          [ngClass]="{
            'success': (
              isValidDeliveryCountryCodes
            ),
            'error': (
              sendAttempted &&
              !isValidDeliveryCountryCodes
            )
          }"
          [placeholder]="(
            getLabelLanguage(
              'select-one'
            )
          )"
          [selected]="deliveryCountryCodes"
          (selectedChange)="onValue(
            'deliveryCountryCodes',
            $event
          )">
          <nb-select-label
            [lang]="languageCode">
            <span>{{
            deliveryCountryNames
          }}</span></nb-select-label>

          <nb-option
            [ngClass]="{
              'disabled': (
                hasDeliveryCountryZone &&
                !isDeliveryCountryZone(
                  option.value
                )
              )
            }"
            [value]="option.value"
            *ngFor="
              let option of optionsDeliveryCountry
            ">{{(
              option
                .label
                .replace(
                  '*',
                  ''
                )
            )}}</nb-option>
        </nb-select>
      </nb-form-field>
    </div>

    <ng-container
      *ngIf="hasServices">
      <nb-form-field>
        <label>{{
            getLabelLanguage(
              (
                hasFulfillment ||
                hasReturns
              ) ?
                'volume-estimate-orders' :
                'volume-estimate-shipments'
            )
          }}&nbsp;(<span
            class="required"
            [ngClass]="{
              'error': (
                sendAttempted &&
                !isValidVolumeEstimate
              )
            }">*</span>)
        </label>

        <nb-select
          [ngClass]="{
            'success': (
              isValidVolumeEstimate
            ),
            'error': (
              sendAttempted &&
              !isValidVolumeEstimate
            )
          }"
          [placeholder]="(
            getLabelLanguage(
              'volume-estimate-placeholder'
            )
          )"
          [selected]="volumeEstimate"
          (selectedChange)="onValue(
            'volumeEstimate',
            $event
          )">
          <nb-select-label
            [lang]="languageCode">
            <span>{{
              optionVolumeEstimateLabel
            }}</span>
          </nb-select-label>
          <nb-option
            [value]="option.value"
            *ngFor="
              let option of optionsVolumeEstimate
            ">{{ option.label }}</nb-option>
        </nb-select>
      </nb-form-field>

      <nb-form-field>
        <label>{{
            getLabelLanguage(
              'parcel-size'
            )
          }}&nbsp;(<span
            class="required"
            [ngClass]="{
              'error': (
                sendAttempted &&
                !isValidParcelSize
              )
            }">*</span>)
        </label>

        <nb-select
          [ngClass]="{
            'success': (
              isValidParcelSize
            ),
            'error': (
              sendAttempted &&
              !isValidParcelSize
            )
          }"
          [placeholder]="(
            getLabelLanguage(
              'parcel-size-placeholder'
            )
          )"
          [selected]="parcelSize"
          (selectedChange)="onValue(
            'parcelSize',
            $event
          )">
          <nb-select-label
            [lang]="languageCode">
            <span>{{
              optionParcelSizeLabel
            }}</span>
          </nb-select-label>
          <nb-option
            [value]="option.value"
            *ngFor="
              let option of optionsParcelSize
            ">{{ option.label }}</nb-option>
        </nb-select>
      </nb-form-field>
    </ng-container>
    <nb-form-field
    *ngIf= "hasParcel" >
    <label>{{
      getLabelLanguage(
        'pick-up-address'
      )
    }}&nbsp;(<span
          class="required"
          [ngClass]="{
            'error': (
              sendAttempted &&
              !isPickUpAddressSafe
            )
          }">*</span>)</label>
    <input
      nbInput
      [placeholder]="(
        getLabelLanguage(
          'pick-up-address-placeholder'
        )
      )"
      [ngClass]="{
        'success': (
          isPickUpAddressSafe
        ),
        'error': (
          sendAttempted &&
          !isPickUpAddressSafe
        )
      }"
      [ngModel]="pickUpAddress"
      (ngModelChange)="onValue(
        'pickUpAddress',
        $event
      )"/>
      </nb-form-field>

      <nb-form-field
      *ngIf="hasParcel">
      <label>{{
          getLabelLanguage(
            'pick-up-country'
          )
        }}&nbsp;(<span
          class="required"
          [ngClass]="{
            'error': (
              sendAttempted &&
              !isPickUpCountrySafe
            )
          }">*</span>)
      </label>

      <nb-select
      [ngClass]="{
        'success': (
          isPickUpCountrySafe
        ),
        'error': (
          sendAttempted &&
          !isPickUpCountrySafe
        )
      }"
        [placeholder]="(
          getLabelLanguage(
            'pick-up-country-placeholder'
          )
        )"
        [selected]="(
          pickUpCountryOptionActiveCode
        )"
        (selectedChange)="(
          setPickUpCountryOptionActive(
            $event,
            true
          )
        )">
        <nb-select-label>{{
          pickUpCountryOptionActiveName
        }}</nb-select-label>

        <nb-option-group
          [title]="(
            getLabelLanguage(
              'country-biz-network'
            )
          )">
          <nb-option
            [value]="pickupcountry.code"
            *ngFor="
              let pickupcountry of (
                countryOptions
                  .preferred
              )
            ">
            {{ pickupcountry.name }}
          </nb-option>
        </nb-option-group>
        
        <nb-option-group
          [title]="(
            getLabelLanguage(
              'country-other'
            )
          )">
          <nb-option
            [value]="pickupcountry.code"
            *ngFor="
              let pickupcountry of (
                countryOptions
                  .other
              )
            ">
            {{ pickupcountry.name }}
          </nb-option>
        </nb-option-group>
      </nb-select>
    </nb-form-field>

      <nb-form-field
      *ngIf= "hasParcel" >
      <label>{{
        getLabelLanguage(
          'delivery-address'
        )
      }}&nbsp;(<span
          class="required"
          [ngClass]="{
            'error': (
              sendAttempted &&
              !isDeliveryAddressSafe
            )
          }">*</span>)</label>
      <input
        nbInput
        [placeholder]="(
          getLabelLanguage(
            'delivery-address-placeholder'
          )
        )"
        [ngClass]="{
          'success': (
            isDeliveryAddressSafe
          ),
          'error': (
            sendAttempted &&
            !isDeliveryAddressSafe
          )
        }"
        [ngModel]="deliveryAddress"
        (ngModelChange)="onValue(
          'deliveryAddress',
          $event
        )"/>
    </nb-form-field>

      <nb-form-field
      *ngIf="hasParcel">
      <label>{{
          getLabelLanguage(
            'delivery-country'
          )
        }}
        &nbsp;(<span
        class="required"
        [ngClass]="{
          'error': (
            sendAttempted &&
            !isDeliveryAddressSafe
          )
        }">*</span>)
      </label>

      <nb-select
      [ngClass]="{
        'success': (
          isDeliveryCountrySafe
        ),
        'error': (
          sendAttempted &&
          !isDeliveryCountrySafe
        )
      }"
        [placeholder]="(
          getLabelLanguage(
            'delivery-country-placeholder'
          )
        )"
        [selected]="(
          deliveryCountryOptionActiveCode
        )"
        (selectedChange)="(
          setDeliveryCountryOptionActive(
            $event,
            true
          )
        )">
        <nb-select-label>{{
          deliveryCountryOptionActiveName
        }}</nb-select-label>

        <nb-option-group
          [title]="(
            getLabelLanguage(
              'country-biz-network'
            )
          )">
          <nb-option
            [value]="deliverycountry.code"
            *ngFor="
              let deliverycountry of (
                countryOptions
                  .preferred
              )
            ">
            {{ deliverycountry.name }}
          </nb-option>
        </nb-option-group>
        
        <nb-option-group
          [title]="(
            getLabelLanguage(
              'country-other'
            )
          )">
          <nb-option
            [value]="deliverycountry.code"
            *ngFor="
              let deliverycountry of (
                countryOptions
                  .other
              )
            ">
            {{ deliverycountry.name }}
          </nb-option>
        </nb-option-group>
      </nb-select>
    </nb-form-field>

    <nb-form-field *ngIf= "hasParcel" >
      <label>{{
          getLabelLanguage(
            'shipment-type'
          )
        }}&nbsp;(<span
          class="required"
          [ngClass]="{
          'success': (
            isValidServiceType
          ),
            'error': (
              sendAttempted &&
              !isValidServiceType
            )
          }">*</span>)
      </label>
      <nb-select
        [ngClass]="{
          'success': (
            isValidServiceType
          ),
          'error': (
            sendAttempted &&
            !isValidServiceType
          )
        }"
        [placeholder]="(
          getLabelLanguage(
            'shipment-type-placeholder'
          )
        )"
        [selected]="shipmentType"
        (selectedChange)="onValue(
          'shipmentType',
          $event
        )">
        <nb-select-label
          [lang]="languageCode">
          <span>{{
            optionShipmentTypeLabel
          }}</span>
        </nb-select-label>
        <nb-option
          [value]="option.value"
          *ngFor="
            let option of optionsShipmentType
          ">{{ option.label }}</nb-option>
      </nb-select>
    </nb-form-field>

    <nb-form-field
    *ngIf= "hasParcel" >
    <label>{{
      getLabelLanguage(
        'weight'
      )
    }}&nbsp;(<span
          class="required"
          [ngClass]="{
          'success': (
            isValidWeight
          ),
            'error': (
              sendAttempted &&
              !isValidWeight
            )
          }">*</span>)</label>
    <input
        type="number"
        step="0.001" 
        min="0.05"
        class="weight"
      nbInput
      [placeholder]="(
        getLabelLanguage(
          'weight-placeholder'
        )
      )"
      [ngClass]="{
        'success': (
          isValidWeight
        ),
        'error': (
          sendAttempted && !isValidWeight
        )
      }"
      [ngModel]="weight"
      (ngModelChange)="onValue(
        'weight',
        $event
      )"/>
    </nb-form-field>
    
    <nb-form-field
      *ngIf="(
        hasFulfillment ||
        hasReturns
      )">
      <label>{{
        getLabelLanguage(
          'industry'
        )
      }}</label>

      <input
        nbInput
        [placeholder]="(
          getLabelLanguage(
            'industry-placeholder'
          )
        )"
        [ngModel]="industry"
        (ngModelChange)="onValue(
          'industry',
          $event
        )"/>
    </nb-form-field>

    <nb-form-field
      *ngIf="hasFulfillment">
      <label>{{
        getLabelLanguage(
          'warehouse-location'
        )
      }}</label>
    
      <nb-select
        [placeholder]="(
          getLabelLanguage(
            'warehouse-location-placeholder'
          )
        )"
        [selected]="warehouseLocation"
        (selectedChange)="onValue(
          'warehouseLocation',
          $event
        )">
        <nb-select-label
          [lang]="languageCode">
          <span>{{
            optionWarehouseLocationLabel
          }}</span>
        </nb-select-label>
        <nb-option
          [value]="option.value"
          *ngFor="
            let option of optionsWarehouseLocation
          ">{{ option.label }}</nb-option>
      </nb-select>
    </nb-form-field>

    <div
      class="form-field checkbox-field"
      *ngIf="hasServices">
      <label>{{
        getLabelLanguage(
          'technologies'
        )
      }}</label>
      <nb-checkbox
        [checked]="technologies[
          $technologyOptionIndex
        ]"
        (checkedChange)="onValue(
          'technologies',
          $event,
          $technologyOptionIndex
        )"
        [ngClass]="{
          'hidden': (
            !(
              hasOptionTechnologyServices(
                option
              )
            )
          )
        }"
        *ngFor="
          let option of optionsTechnology;
          let $technologyOptionIndex = index;
        ">{{
          option.label
        }}</nb-checkbox>
    </div>

    <div
      class="declaration desktop"
      [lang]="languageCode"
      [ngClass]="{
        'has-services': (
          hasServices
        )
      }">
      <nb-checkbox
        [ngModel]="declarationAccepted"
        (ngModelChange)="onValue(
          'declarationAccepted',
          $event
        )">
      </nb-checkbox>
      <div
        class="message">
        {{ declaration }}
      </div>
    </div>

    <div
      class="declaration handheld"
      [lang]="languageCode"
      [ngClass]="{
        'has-services': (
          hasServices
        )
      }">
      <nb-checkbox
        [ngModel]="declarationAccepted"
        (ngModelChange)="onValue(
          'declarationAccepted',
          $event
        )">
      </nb-checkbox>
      <div
        class="message">
        {{ declaration }}
      </div>
    </div>

    <div
      class="actions desktop">
      <button
        nbButton
        ngxTooltip
        class="send-button"
        status="primary"
        tooltipGroup="button-send"
        tooltipTrigger="manual"
        [tooltipContent]="(
          isValid ?
            (
              declarationAccepted ?
                messageIpAddress :
                messageDeclaration
            ) :
            (
              !(
                isValidPhone ||
                isValidPhoneEmpty
              ) ?
                (
                  (
                    isRequiredPhone &&
                    isEmptyPhone
                  ) ?
                    (
                      isValidExclMessage ?
                        messageValidationMessage :
                        messageValidation
                    ) :
                    messageValidationInclPhone
                ) :
                (
                  isValidExclMessage ?
                    messageValidationMessage :
                    messageValidation
                )
            )
        )"
        [lang]="languageCode"
        [ngClass]="{
          'disabled': (
            sending ||
            (
              !(
                isValid &&
                isValidIpAddress &&
                declarationAccepted
              )
            )
          )
        }"
        (click)="onSend()">{{
          getLabelLanguage(
            'send-button'
          )
        }}</button>
    </div>

    <div
      class="actions handheld">
      <button
        nbButton
        ngxTooltip
        class="send-button"
        status="primary"
        tooltipGroup="button-send"
        tooltipTrigger="manual"
        [tooltipContent]="(
          isValid ?
            (
              declarationAccepted ?
                messageIpAddress :
                messageDeclaration
            ) :
            (
              !(
                isValidPhone ||
                isValidPhoneEmpty
              ) ?
                (
                  (
                    isRequiredPhone &&
                    isEmptyPhone
                  ) ?
                    (
                      isValidExclMessage ?
                        messageValidationMessage :
                        messageValidation
                    ) :
                    messageValidationInclPhone
                ) :
                (
                  isValidExclMessage ?
                    messageValidationMessage :
                    messageValidation
                )
            )
        )"
        [lang]="languageCode"
        [ngClass]="{
          'disabled': !(
            isValid &&
            isValidIpAddress &&
            declarationAccepted
          )
        }"
        (click)="onSend()">{{
          getLabelLanguage(
            'send-button'
          )
        }}</button>
    </div>
  </div>
</div>
</div>