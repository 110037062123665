export default {
  'contact': {
    'en': 'Contact us',
    'el': 'Επικοινωνία',
    'bg': 'Връзка с нас',
    'fr': 'Nous contacter',
    'de': 'Kontakt',
    'de-at': 'Kontakt',
    'es': 'Contacto',
    'it': 'Contatti',
    'hr': 'Kontaktirajte s nama',
    'hu': 'Kapcsolatfelvétel',
    'pl': 'Kontakt',
    'pt': 'Entre em contacto',
    'ro': 'Contactați-ne',
    'ru': 'Свяжитесь с нами',
    'sk': 'Kontaktujte nás',
    'sl': 'Stopite v stik z nami',
    'cs': 'Kontaktujte nás'
  },
  'offices': {
    'en': 'Offices',
    'el': 'Γραφεία',
    'bg': 'Офиси',
    'fr': 'Bureaux',
    'de': 'Büros',
    'de-at': 'Büros',
    'es': 'Oficinas',
    'it': 'Uffici',
    'hr': 'Uredi',
    'hu': 'Irodák',
    'pl': 'Biura',
    'pt': 'Escritórios',
    'ro': 'Birouri',
    'ru': 'Офисы',
    'sk': 'Pobočky',
    'sl': 'Pisarne',
    'cs': 'Kanceláře'
  },
  'working-hours': {
    'en': 'Working Hours',
    'el': 'Ώρες Λειτουργίας',
    'bg': 'Работно време',
    'fr': 'Heures d’ouverture',
    'de': 'Bürozeiten',
    'de-at': 'Bürozeiten',
    'es': 'Horario laboral',
    'it': 'Orario di apertura',
    'hr': 'Radno vrijeme',
    'hu': 'Nyitva tartás',
    'pl': 'Godziny pracy',
    'pt': 'Horas de funcionamento',
    'ro': 'Ore de lucru',
    'ru': 'Часы работы',
    'sk': 'Úradné hodiny',
    'sl': 'Delovni čas',
    'cs': 'Pracovní doba'
  },
  'monday': {
    'en': 'Monday',
    'el': 'Δευτέρα',
    'bg': 'Понеделник',
    'fr': 'Lundi',
    'de': 'Montag',
    'de-at': 'Montag',
    'es': 'Lunes',
    'it': 'Lunedì',
    'hr': 'Ponedjeljak',
    'hu': 'Hétfő',
    'pl': 'Poniedziałek',
    'pt': 'Segunda-feira',
    'ro': 'luni',
    'ru': 'Понедельник',
    'sk': 'Pondelok',
    'sl': 'Ponedeljek',
    'cs': 'Pondělí'
  },
  'friday': {
    'en': 'Friday',
    'el': 'Παρασκευή',
    'bg': 'Петък',
    'fr': 'Vendredi',
    'de': 'Freitag',
    'de-at': 'Freitag',
    'es': 'Viernes',
    'it': 'Venerdì',
    'hr': 'Petak',
    'hu': 'Péntek',
    'pl': 'Piątek',
    'pt': 'Sexta-feira',
    'ro': 'vineri',
    'ru': 'Пятница',
    'sk': 'Piatok',
    'sl': 'Petek',
    'cs': 'Pátek'
  },
  'address': {
    'en': '8th Km Varis Koropiou Avenue',
    'el': '8o χλμ. Βάρης - Κορωπίου',
    'bg': '8th Km Varis Koropiou',
    'fr': '8e km route Varis Koropiou',
    'de': '8th km Varis Koropiou',
    'de-at': '8th km Varis Koropiou',
    'es': '8th Km Varis Koropiou',
    'it': 'km 8 Leof. Vari-Koropi',
    'hr': '8th Km Varis Koropiou',
    'hu': '8th Km Varis Koropiou',
    'pl': '8th Km Varis Koropiou',
    'pt': '8th Km Varis Koropiou',
    'ro': 'Km 8 Varis Koropiou ',
    'ru': '8-й км Varis Koropiou',
    'sk': '8th Km Varis Koropiou',
    'sl': '8th Km Varis Koropiou',
    'cs': '8th Km Varis Koropiou'
  },
  'athens': {
    'en': 'Athens',
    'el': 'Αθήνα',
    'bg': 'Атина',
    'fr': 'Athènes',
    'de': 'Athen',
    'de-at': 'Athen',
    'es': 'Atenas',
    'it': 'Atene',
    'hr': 'Atena',
    'hu': 'Athens',
    'pl': 'Ateny',
    'pt': 'Atenas',
    'ro': 'Atena',
    'ru': 'Афины',
    'sk': 'Atény',
    'sl': 'Atene',
    'cs': 'Athény'
  },
  'greece': {
    'en': 'Greece',
    'el': 'Ελλάδα',
    'bg': 'Гърция',
    'fr': 'Grèce',
    'de': 'Griechenland',
    'de-at': 'Griechenland',
    'es': 'Grecia',
    'it': 'Grecia',
    'hr': 'Grčka',
    'hu': 'Görögország',
    'pl': 'Grecja',
    'pt': 'Grécia',
    'ro': 'Grecia',
    'ru': 'Греция',
    'sk': 'Grécko',
    'sl': 'Grčija',
    'cs': 'Řecko'
  },
  'leipzig': {
    'en': 'Leipzig',
    'el': 'Λειψία',
    'bg': 'Лайпциг',
    'fr': 'Leipzig',
    'de': 'Leipzig',
    'de-at': 'Leipzig',
    'es': 'Leipzig',
    'it': 'Lipsia',
    'hr': 'Leipzig',
    'hu': 'Lipcse',
    'pl': 'Lipsk',
    'pt': 'Leipzig',
    'ro': 'Leipzig',
    'ru': 'Лейпциг',
    'sk': 'Lipsko',
    'sl': 'Leipzig',
    'cs': 'Lipsko'
  },
  'germany': {
    'en': 'Germany',
    'el': 'Γερμανία',
    'bg': 'Германия',
    'fr': 'Allemagne',
    'de': 'Deutschland',
    'de-at': 'Deutschland',
    'es': 'Alemania',
    'it': 'Germania',
    'hr': 'Njemačka',
    'hu': 'Németország',
    'pl': 'Niemcy',
    'pt': 'Alemanha',
    'ro': 'Germania',
    'ru': 'Германия',
    'sk': 'Nemecko',
    'sl': 'Nemčija',
    'cs': 'Německo'
  }
};